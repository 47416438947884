import { wrapGrid } from 'animate-css-grid';
import { Controller } from 'stimulus';

const refreshInterval = 10000;
const blinkDuration = 1400;

export default class extends Controller {
    intervals = [];
    fullScreen = false;
    lastMouseMoveTimeout = null;

    refreshDataSupportTicketsCompletion(_) {
        let urlNew = '/api/support-ticket/since/';
        let urlRefresh = '/api/support-ticket/data/';

        let supportTicketsIds = jQuery('.grid-item[data-id]').map((_, st) => parseInt(jQuery(st).data('id'))).toArray();
        
        jQuery.get(urlNew + Math.max(...supportTicketsIds), {}, response => {
            if (response != null) {
                JSON.parse(response).forEach(tile => {
                    jQuery(".group-lane[data-group=" + tile.groupId +"] > .animated-grid").prepend(tile.htmlContent)
                });
            }   
        });

        // Update data in support ticket tiles
        jQuery.post(urlRefresh, {
            ids: JSON.stringify(supportTicketsIds)
        }, response => {
            JSON.parse(response).forEach(ticket => {
                let tile = jQuery(`.grid-item[data-id="${ticket.id}"]`);
                if(ticket.state < 3 || ticket.state == 5 || ticket.groupId == null) {
                    let currentGroupId = tile.parentsUntil('.grid-wrapper').last().data('group');
                    if(ticket.groupId != null && currentGroupId != ticket.groupId) {
                        let newGroup = jQuery(`.group-lane[data-group="${ticket.groupId}"]`);
                        let nextTileId = newGroup.find('.grid-item[data-id]').map((_, t) => parseInt($(t).data('id'))).toArray().filter(id => id < ticket.id).reduce((a, b) => Math.max(a, b), 0);
                        if(nextTileId) {
                            jQuery(`.grid-item[data-id="${nextTileId}"]`).before(tile);
                        } else {
                            newGroup.find('.animated-grid').append(tile);
                        }
                    }
                    
                    let tileProperties = tile.find(" *[data-property]");
                    tileProperties.each((_, p) => {
                        if(jQuery(p).data('property'))
                            jQuery(p).html(ticket[jQuery(p).data('property')]);
                    });
    
                    tile.removeClass('ticket-priority--1');
                    tile.removeClass('ticket-priority-0');
                    tile.removeClass('ticket-priority-1');
                    tile.removeClass('ticket-priority-2');
                    tile.removeClass('ticket-priority-3');
                    tile.addClass('ticket-priority-' + ticket.timeRelativePriority);
                } else {
                    jQuery(`.grid-item[data-id="${ticket.id}"]`).remove();
                }

            });

            setTimeout(() => {
                jQuery('.grid-item').off('click');
                jQuery('.grid-item').on('click', function() { jQuery(this).toggleClass('main-grid-item') });
                jQuery('.group-lane').each((_, groupLane) => jQuery(groupLane).css('flex-grow', jQuery(groupLane).find('.grid-item').length))
            }, 500); 
        });
    }

    initRefreshDataListener() {
        this.intervals.push(setInterval((args) => this.refreshDataSupportTicketsCompletion(args), refreshInterval));
    }

    tileDragStart(e) {
        if(!e.dataTransfer) e.dataTransfer = e.originalEvent.dataTransfer;
        e.dataTransfer.setData('entityId', jQuery(e.target).data('id'));
        e.dataTransfer.setData('groupId', jQuery(e.target).parentsUntil('.grid-wrapper').last().data('group'));
    }

    laneDragOver(e) {
        e.preventDefault();
    }

    laneDragStop(e) {
        e.preventDefault();
        if(!e.dataTransfer) e.dataTransfer = e.originalEvent.dataTransfer;
        console.log(e.target);

        let ticketId = e.dataTransfer.getData('entityId');
        let groupId = e.dataTransfer.getData('groupId');
        if(groupId != $(this).data('group')) {
            let tile = jQuery(`.grid-item[data-id="${ticketId}"]`);
            let newGroup = $(this);
            let nextTileId = newGroup.find('.grid-item[data-id]').map((_, t) => parseInt($(t).data('id'))).toArray().filter(id => id < ticketId).reduce((a, b) => Math.max(a, b), 0);
            if(nextTileId) {
                jQuery(`.grid-item[data-id="${nextTileId}"]`).before(tile);
            } else {
                newGroup.find('.animated-grid').append(tile);
            }

            jQuery.post('/support-ticket/group?entityId=' + ticketId, {
                'groupId': $(this).data('group')
            }).then(() => refreshDataSupportTicketsCompletion());
        }
    }

    connect() {
        jQuery('.grid-item').on('click', function() { jQuery(this).toggleClass('main-grid-item') })
        const grids = document.querySelectorAll(".animated-grid");
        grids.forEach(grid => wrapGrid(grid, { duration: 400 }));

        setInterval(() => jQuery('.grid-item.ticket-priority--1').addClass('blink-alert'), blinkDuration);
        setTimeout(() => setInterval(() => jQuery('.grid-item').removeClass('blink-alert'), blinkDuration), blinkDuration / 2);

        jQuery('.mousemove-button .btn').on('click', () => {
            jQuery('.content-wrapper').toggleClass('full-screen');
            this.fullScreen = jQuery('.content-wrapper').hasClass('full-screen');
            jQuery('.mousemove-button .btn').text(this.fullScreen ? 'Réduire' : 'Plein écran');
            if(!this.fullScreen) {
                jQuery('.mousemove-button').fadeIn(0);
                this.lastMouseMoveTimeout = null;
            }
        });

        jQuery(document).on('mousemove', () => {
            jQuery('html').css('cursor', '');
            if(this.fullScreen) {
                jQuery('.mousemove-button').fadeIn(200);
                let mouseMoveTimeout = setTimeout(() => {
                    if(this.lastMouseMoveTimeout == mouseMoveTimeout){
                        jQuery('html').css('cursor', 'none');
                        jQuery('.mousemove-button').fadeOut(1000);
                    }
                }, 1000);
                this.lastMouseMoveTimeout = mouseMoveTimeout;
            }
        });

        // Drag and drop tiles

        jQuery('.grid-item').on('dragstart', this.tileDragStart);
        jQuery('.group-lane').on('dragover', this.laneDragOver);
        jQuery('.group-lane').on('drop', this.laneDragStop);

        this.initRefreshDataListener();
    }

    destroy() {
        this.intervals.forEach(interval => window.clearInterval(interval));
    }
}