import { Controller } from 'stimulus';

export default class extends Controller {
    static values = {
        selector:  String,
    }

    eventPrevented;
    retriggering = false;
    targetElement;

    connect() {
        jQuery(this.selectorValue).on('click', (e) => {
            if(this.retriggering){
                this.retriggering = false;
                return;
            }

            this.targetElement = e.target;

            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();
            this.eventPrevented = e;
            jQuery.noConflict(false);
            jQuery(this.element).modal('show');
        });

        jQuery(this.element).find('.modal-confirm-button').on('click', (e) => {
            this.eventPrevented.isDefaultPrevented = function(){ return false; }
            this.eventPrevented.isImmediatePropagationStopped = function(){ return false; }
            this.eventPrevented.isPropagationStopped = function(){ return false; }
            this.retriggering = true;
            jQuery(this.targetElement ?? this.selectorValue).trigger(this.eventPrevented)
        });
    }
}
