import { Controller } from 'stimulus';

const SUPPORT_TICKET_GROUP = 'support-ticket-group-select';
const SUPPORT_TICKET_PRIORITY = 'support-ticket-priority-select';
const SUPPORT_TICKET_STATE = 'support-ticket-state-select';

export default class extends Controller {

    static values = {
        urlActions: Object,
        placeholder: String
    }

    formatResult(state) {
        const option = jQuery(state.element);
        const classesOption = option.attr("data-class");
        if (!classesOption) {
            return state.text;
        }
        return jQuery(`<span class="select-option ${classesOption}">${state.text}</span>`);
    }

    formatSelection(state) {
        const option = jQuery(state.element);
        const classesOption = option.attr("data-class");
        if (!classesOption) {
            return state.text;
        }
        return jQuery(`<span class="select-selection ${classesOption}">${state.text}</span>`);
    }

    changeEntityValueQuery(constant, property, errorMessage = 'Erreur', data = {}) {
        data['entityId'] = jQuery(this.element).attr('data-id');
        data[property] = jQuery('#' + constant).val();

        return jQuery.post(this.urlActionsValue[constant], data).catch(e => {
            alert(errorMessage)
        });
    }

    setSupportTicketState(e) {
        e.stopPropagation()
        let state = jQuery(this.element).val();
        if(state == jQuery(this.element).attr('data-state')) return;
        else if(state == 3) { // Ticket résolu
            jQuery('#modalTicketClosure').modal('show');
        } else if(state == 4 || state == 6 || state == 7) {
            jQuery('#modalTicketCancel').modal('show');
        } else if(jQuery(this.element).attr('data-state') == 3) { // Réouverture d'un ticket clos
            if(confirm('Êtes-vous sûr de vouloir réouvrir ce ticket ?')) {
                this.changeEntityValueQuery(SUPPORT_TICKET_STATE, 'state', 'Erreur : impossible de mettre à jour l\'état du ticket');
                jQuery(this.element).attr('data-state', state);
            }
        } else {
            this.changeEntityValueQuery(SUPPORT_TICKET_STATE, 'state', 'Erreur : impossible de mettre à jour l\'état du ticket');
            jQuery(this.element).attr('data-state', state);
        }
    }

    cancelSupportTicket() {
        let state = jQuery(this.element).val();
        this.changeEntityValueQuery(SUPPORT_TICKET_STATE, 'state', 'Erreur : impossible de mettre à jour l\'état du ticket')
            .then(() => window.location.reload());
        jQuery(this.element).attr('data-state', state);
    }

    closeSupportTicket() {
        let state = jQuery(this.element).val();
        this.changeEntityValueQuery(SUPPORT_TICKET_STATE, 'state', 'Erreur : impossible de mettre à jour l\'état du ticket', {
            'problemOrigin': jQuery('#modalTicketClosureProblemOrigin').val(),
            'responsability': jQuery('#modalTicketClosureResponsability').val()
        }).then(() => window.location.reload());
        jQuery(this.element).attr('data-state', state);
    }

    connect() {
        if(this.element.id == 'modalTicketClosureResponsability' || this.element.id == 'modalTicketClosureProblemOrigin') {
            jQuery(this.element).select2({
                dropdownParent: jQuery('#modalTicketClosure'),
                tags: true
            });
        } else if(this.element.id == 'modalWriteEmailSubject') {
            jQuery(this.element).select2({
                dropdownParent: jQuery('#modalWriteEmail'),
                tags: true
            });
        } else if(this.element.id == 'support-ticket-group-select') {
            jQuery(this.element).select2({
                templateResult: this.formatResult,
                templateSelection: this.formatSelection,
                placeholder: this.placeholderValue ?? '',
                theme: 'expand-content'
            });
        } else if(this.element.id == 'MailTemplateStep_sendTo') {
            jQuery(this.element).select2({
                theme: 'bootstrap',
                tags: true
            });
        } else {
            jQuery(this.element).select2({
                templateResult: this.formatResult,
                templateSelection: this.formatSelection,
                placeholder: this.placeholderValue ?? '',
                tags: jQuery(this.element).data('tags') ?? false,
            });
        }

        switch(this.element.id) {
            case SUPPORT_TICKET_GROUP:
                jQuery(this.element).on('change', () => this.changeEntityValueQuery(SUPPORT_TICKET_GROUP, 'groupId', 'Erreur : impossible de réassigner le groupe du ticket')); break;
            case SUPPORT_TICKET_PRIORITY:
                jQuery(this.element).on('change', () => this.changeEntityValueQuery(SUPPORT_TICKET_PRIORITY, 'priority', 'Erreur : impossible de mettre à jour l\'importance du ticket')); break;
            case SUPPORT_TICKET_STATE:
                jQuery(this.element).on('change', (e) => this.setSupportTicketState(e));
                jQuery('#modalTicketCancel .modal-confirm-button').on('click', () => this.cancelSupportTicket());
                jQuery('#confirmModalTicketClosure').on('click', () => this.closeSupportTicket());
                jQuery('#modalTicketCancel').on('hidden.bs.modal', () => jQuery(this.element).val(jQuery(this.element).attr('data-state')) && jQuery('#support-ticket-state-select').trigger('change'));
                jQuery('#modalTicketClosure').on('hidden.bs.modal', () => jQuery(this.element).val(jQuery(this.element).attr('data-state')) && jQuery('#support-ticket-state-select').trigger('change'));
                break;
        }
    }
}
