import 'moment';
import { Controller } from 'stimulus';

export default class extends Controller {

   static values = {
      urlData : String,
      pageSize : Number,
      filters : Object,
      incompatibleFilters: Array
   }

   filters;
   isLoading = false;
   reachedEndOfTimeline = false;
   currentPage = 0;

   VerticalTimeline = (element) => {
      this.element = element;
      this.blocks = jQuery(this).find(".timeline-block");
      this.images = jQuery(this).find(".timeline-img");
      this.contents = jQuery(this).find(".timeline-content");
   }

   onSetFilter(e, data) {
      if(this.incompatibleFiltersValue.includes(data.filter))
         this.incompatibleFiltersValue.forEach(filter => delete this.filters[filter]);

      this.filters[data.filter] = data.values;
      
      this.reload();
   }

   onUnsetFilter(e, data) {
      if(data.isMultiSelection) {
         const index = this.filters[data.filter].indexOf(data.value);
         if (index > -1) this.filters[data.filter].splice(index, 1);
         if (!this.filters[data.filter].length) delete this.filters[data.filter];
      } else {
         delete this.filters[data.filter];
      }
      if(!this.filters.entityName) this.filters.entityName = this.filtersValue.entityName;
      this.reload();
   }

   scrollBottom() {
      let wrapper = jQuery('.timeline');
      let viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      if(
         !this.isLoading
         && !this.reachedEndOfTimeline
         &&  jQuery(document).scrollTop() >= wrapper.outerHeight(true) - viewportHeight
      ) {
         this.loadMore();
      }
   }

   reload() {
      jQuery('.timeline-container').html('');
      this.currentPage = 0;
      this.reachedEndOfTimeline = false;
      this.isLoading = false;
      this.loadMore();
   }

   loadMore() {
      this.isLoading = true;
      jQuery.post(this.urlDataValue, {
            'pageSize': this.pageSizeValue,
            'offset': this.currentPage * this.pageSizeValue,
            'filters': this.filters
         }, (json) => {
            if(json && JSON.parse(json).content) {
               let data = JSON.parse(json);
               jQuery('.timeline-container').append(data.content);
               jQuery('#totalCount').text(data.totalCount)
               this.currentPage = data.page;
               if(data.pageCount < this.pageSizeValue) this.reachedEndOfTimeline = true;

               this.wrapModalEntries();
            } else {
               if(this.currentPage == 0) jQuery('#totalCount').text(0);
               this.reachedEndOfTimeline = true;
            }
         }, 'html'
      ).always(e => {
         this.isLoading = false;
      })
   }

   wrapModalEntries() {
      jQuery('.timeline-entry .modal-content-to-wrap').each((_, elem) => {
         let elemId = 'entry_' + jQuery(elem).parentsUntil('.timeline-container').last().data('id') + '_modal';
         jQuery(elem).before(`
            <a href="#" data-toggle="modal" data-target="#${elemId}">
               <i class="far fa-file-alt"></i> Voir les détails du mail
            </a>
            <div class="modal fade" id="${elemId}" tabindex="-1" role="dialog" aria-hidden="true">
               <div class="modal-dialog" role="document">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title">Détails du mail envoyé</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Fermer">
                              <span aria-hidden="true">×</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div>${jQuery(elem).html()}</div>
                     </div>
                  </div>
               </div>
            </div>
         `);
      });
      jQuery('.timeline-entry .modal-content-to-wrap').remove();
   }

   connect() {
      this.VerticalTimeline.prototype.showBlocks = function() {
         var self = jQuery(this);
         for(var i = 0; i < this.blocks.length; i++) {
            (function(i){
               if(self.contents()[i].hasClass("timeline-content--hidden") && self.block[i].getBoundingClientRect().top <= window.innerHeight*self.offset ) {
                  // add bounce-in animation
                  self.images[i].addClass("timeline-img--bounce-in");
                  self.contents()[i].animation("timeline-content--bounce-in");
                  self.images[i].removeClass("timeline-img--hidden");
                  self.contents()[i].removeClass("timeline-content--hidden");
               }
            })(i);
         }
      };

      jQuery(this.element).find('.timeline-block').on('click', function() {
         jQuery(this).find('.timeline-content').toggleClass('expanded');
      });

      jQuery(document).on('scroll', () => setTimeout(() => this.scrollBottom(), 100));

      jQuery(document).on('set-filter', (e, data) => this.onSetFilter(e, data));
      jQuery(document).on('unset-filter', (e, data) => this.onUnsetFilter(e, data));
      this.filters = this.filtersValue != null ? Object.assign({}, this.filtersValue) : [];
      this.loadMore();
    }
}
