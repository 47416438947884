import { Controller } from 'stimulus';

export default class extends Controller {

    connect() {
        jQuery('.action-page-size select').on('change', (e) => {
            document.location.href = document.location.href.replace(/&pageSize=[0-9]*/, '') + '&pageSize=' + jQuery(e.target).val()
        })

        jQuery('.form-batch-checkbox').on('change', e => {
            if(jQuery('tbody .form-batch-checkbox:checked').length == 0) return;
            let serviceName = jQuery('tbody .form-batch-checkbox:checked').first().parentsUntil('tbody').last().find('.so-service a').text();
            let differentServicesSelected = jQuery('.form-batch-checkbox:checked').filter((_, checkbox) => jQuery(checkbox).parentsUntil('tbody').last().find('.so-service a').text() != serviceName);
            jQuery('.action-generateDeliveryOrderBatch').attr('disabled', differentServicesSelected.length > 0);
        });
        var title = jQuery('.content-header-title .title');
        title.css('font-size', 'var(--font-size-xl)');
        title.css('line-height', 1);
        title.css('color', '#fff');
        title.css('margin', 0);
        title.removeClass('title');
        jQuery('.action-fakeDelete').css('pointer-events', 'auto')
    }
}

