import { Controller } from 'stimulus';
import CustomMailPreview from '../js/custom-mail-preview';

export default class extends Controller {
    serviceId;

    connect() {
        if(jQuery('body').hasClass('index-Project') || jQuery('body').hasClass('detail-Service'))
            (new CustomMailPreview('Envoi d\'un e-mail', (e) => (new URLSearchParams(window.location.search)).get('entityId') ?? jQuery(e.target).parentsUntil('tbody').last().attr('sub-data-id'))).enable('.action-sendMail');
    }
}