import { Controller } from 'stimulus';

export default class extends Controller {
    static values = {
        suffix: String
    }

    displayEditModal() {
        jQuery(this.element).find('.modal').modal('show');
    }

    refreshValueDisplay() {
        jQuery(this.element).find('.value-display').html(jQuery(this.element).find('.value-field > *').val() + this.suffixValue);
    }

    checkAdminValueModification() {
        jQuery.ajax({
            type: "POST",
            url: '/api/admin/action/auth/',
            data: { password: jQuery(this.element).find('.modal #password').val() },
            success: () => {
                jQuery(this.element).find('.value-field > *').val(jQuery(this.element).find('.modal #newValue').val());
                jQuery(this.element).find('.value-field > *').trigger('change');
            },
            error: () => alert('Le mot de passe est incorrect')
        });
    }

    connect() {
        jQuery(this.element).find('.value-field > *').on('change', () => this.refreshValueDisplay())
        this.refreshValueDisplay();
    
        if(jQuery(this.element).find('.value-field > input').length) {
            jQuery(this.element).find('.modal input#newValue').attr('type' ,jQuery(this.element).find('.value-field > input').attr('type'));
            jQuery(this.element).find('.modal select#newValue').remove();
        } else if(jQuery(this.element).find('.value-field > select').length) {
            jQuery(this.element).find('.value-field > select option').clone().appendTo(jQuery(this.element).find('.modal select#newValue'));
            jQuery(this.element).find('.modal select#newValue').select2({ theme: 'bootstrap' })
            jQuery(this.element).find('.modal input#newValue').remove();
        }
        
        jQuery(this.element).find('.form-widget i').on('click', () => this.displayEditModal())
        jQuery(this.element).find('.modal-confirm-button').on('click', () => this.checkAdminValueModification())
    }
}
