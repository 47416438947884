import { Controller } from 'stimulus';

export default class extends Controller {

    round(nb, precision) {
        return Math.round(nb * Math.pow(10, precision)) / Math.pow(10, precision);
    }

    connect() {
        jQuery(this.element).find('.completion-bar').css('width', jQuery(this.element).attr('data-percentage') + '%');
        jQuery(this.element).find('.progress-percentage').text(this.round(jQuery(this.element).attr('data-percentage'), 2) + '%');
        jQuery(this.element).attr('title', jQuery(this.element).attr('data-percentage') + '%');
        var observer = new MutationObserver((mutations) => {
            jQuery(this.element).find('.completion-bar').css('width', jQuery(this.element).attr('data-percentage') + '%');
            jQuery(this.element).find('.progress-percentage').html(this.round(jQuery(this.element).attr('data-percentage'), 2) + '%');
            jQuery(this.element).attr('title', jQuery(this.element).attr('data-percentage') + '%');
          });
        observer.observe(this.element, { 
            attributes: true,
            attributeFilter: ['data-percentage']
        });
          
    }
}