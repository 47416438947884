import autosize from 'autosize';
import { Controller } from 'stimulus';
import TrixAttachmentHandler from '../js/trix-attachment-handler';

function createFormData(commentId, file) {
    var data = new FormData()
    data.append("commentId", commentId)
    data.append("Content-Type", file.type)
    data.append("file", file)
    return data
}

export default class extends Controller {
    writeCommentUrl;
    writeEmailUrl;
    entityId;
    trixAttachmentHandlerSendMail;
    trixAttachmentHandlerNewComment;
    trixAttachmentHandlersEditComment = [];

    showModalWriteComment(e) {
        e.preventDefault();
        e.stopPropagation();

        jQuery.noConflict(false);
        jQuery('#modalWriteComment').modal('show');
    }

    showModalWriteEmail(e) {
        e.preventDefault();
        e.stopPropagation();

        jQuery('#modalWriteEmail').modal('show');
    }

    showModalWriteNotes(e) {
        e.preventDefault();
        e.stopPropagation();

        this.writeNotesUrl = jQuery(e.target).attr('href');

        jQuery('#modalWriteNotes').modal('show');
    }

    showEditCommentTextarea(e) {
        jQuery(e.target).parentsUntil('.activity-feed').last().find('.comment-text').css('display', 'none');
        jQuery(e.target).parentsUntil('.activity-feed').last().find('.comment-text-editor').css('display', 'block');
    }

    hideEditCommentTextarea(e) {
        jQuery(e.target).parentsUntil('.activity-feed').last().find('.comment-text').css('display', 'block');
        jQuery(e.target).parentsUntil('.activity-feed').last().find('.comment-text-editor').css('display', 'none');
    }

    writeComment(e, data, trixAttachmentHandler) {
        jQuery(e.target).parentsUntil('.modal-dialog').find('.modal-footer button').hide();
        jQuery(e.target).parentsUntil('.modal-dialog').find('.spinner').show();
        document.body.style.cursor='wait';
        data.entityId = this.entityId;

        jQuery.post(this.writeCommentUrl, data).then(commentId => {
            let attachmentFiles = trixAttachmentHandler.getAttachments() ?? [];
            let promises = [];
            Object.values(attachmentFiles).forEach(file => {
                promises.push(
                    jQuery.ajax({
                        url: '/support-ticket/comment/' + commentId,
                        data: createFormData(commentId, file),
                        type: 'POST',
                        contentType: false,
                        processData: false
                    })
                );
            });

            Promise.all(promises).then(() => document.location.reload());
            if(!data.commentId) this.hideEditCommentTextarea(e);
        }).catch(e => {
            jQuery('#modalWriteComment').modal('hide');
            document.body.style.cursor= 'default';
            jQuery(e.target).parentsUntil('.modal-dialog').find('.modal-footer button').show();
            jQuery(e.target).parentsUntil('.modal-dialog').find('.spinner').hide();
            if(!data.commentId) this.hideEditCommentTextarea(e);
        });

        e.stopPropagation();
        e.preventDefault();
    }

    writeEmail(e) {
        jQuery(e.target).parentsUntil('.modal-dialog').find('.modal-footer button').hide();
        jQuery(e.target).parentsUntil('.modal-dialog').find('.spinner').show();
        document.body.style.cursor='wait';

        let data = new FormData();
        data.append('entityId', this.entityId);
        data.append('subject', jQuery('#modalWriteEmailSubject').val());
        data.append('content', jQuery('#modalWriteEmailContent').val());
        data.append('to', jQuery('#modalWriteEmail [name="to"]').val().toString().replace(/,/g, ';'));
        data.append('cc', jQuery('#modalWriteEmail [name="cc"]').val().toString().replace(/,/g, ';'));
        data.append('bcc', jQuery('#modalWriteEmail [name="bcc"]').val().toString().replace(/,/g, ';'));

        let attachments = this.trixAttachmentHandlerSendMail.getAttachments() ?? {};
        Object.keys(attachments).forEach((fileHash, i) => {
            data.append("Content-Type", attachments[fileHash].type);
            data.append('file' + i, attachments[fileHash]);
        }, this);

        jQuery.ajax({
            url: this.writeEmailUrl,
            data: data,
            type: 'POST',
            cache: false,
            contentType: false,
            processData: false,
        }).then(_ => {
            document.location.reload();
        }).catch(e => {
            jQuery('#modalWriteComment').modal('hide');
            document.body.style.cursor= 'default';
            jQuery(e.target).parentsUntil('.modal-dialog').find('.modal-footer button').show();
            jQuery(e.target).parentsUntil('.modal-dialog').find('.spinner').hide();
        });
    }

    writeNotes(data) {
        data.content = jQuery('#modalWriteNotesContent').val();

        jQuery.post(this.writeNotesUrl, data).then(() => {
            document.location.reload();
        }).catch(e => {
            jQuery('#modalWriteNotes').modal('hide');
        });
    }

    editComment(e) {
        let commentElem = jQuery(e.target).parentsUntil('.activity-feed').last();
        this.writeComment(e, {
            commentId: commentElem.attr('data-id'),
            comment: commentElem.find('.comment-text-editor input[type="hidden"]').val()
        }, this.trixAttachmentHandlersEditComment[commentElem.attr('data-id')]);
    }

    deleteFile(e) {
        if(!e.isDefaultPrevented())
            jQuery.ajax({ url: '/api/file-attachment/' + jQuery(e.target).parent().data('id'), method: 'DELETE' }).then(() => document.location.reload());
    }

    connect() {
        var list = document.querySelector('.activity-feed');
        [...list.children].sort((a,b)=> $(b).attr('data-timestamp') - $(a).attr('data-timestamp')).forEach(node=>list.appendChild(node));

        jQuery("trix-initialize").contentEditable = false

        jQuery('.action-comment').on('click', (e) => this.showModalWriteComment(e))
        jQuery('.action-sendMail').on('click', (e) => this.showModalWriteEmail(e))
        jQuery('#confirmModalWriteComment').on('click', (e) => this.writeComment(e, { comment: jQuery('#modalWriteCommentContent').val() }, this.trixAttachmentHandlerNewComment))
        jQuery('#confirmModalWriteEmail').on('click', (e) => this.writeEmail(e))
        jQuery('.fa-pen').on('click', (e) => this.showEditCommentTextarea(e))
        setTimeout(() => jQuery('.comment-file .fa-times').on("click", (e) => this.deleteFile(e)), 200);
        jQuery('.cancel-comment-edit').on('click', (e) => this.hideEditCommentTextarea(e))
        jQuery('.confirm-comment-edit').on('click', (e) => this.editComment(e))
        autosize(jQuery('.comment-text'));
        jQuery('.comment-text img').each((i, img) => {
            let imgFileName = jQuery(img).attr('src').split('@')[0].replace('cid:', '');
            let correspondingAttachment = jQuery(`.comment-file a[download="${imgFileName}"]`);
            if(correspondingAttachment) jQuery(img).attr('src', correspondingAttachment.attr('href'));
        })
        this.writeCommentUrl = jQuery('.action-comment').attr('href')
        this.writeEmailUrl = jQuery('.action-sendMail').attr('href')
        this.entityId = jQuery(this.element).attr('data-id');

        this.trixAttachmentHandlerNewComment = new TrixAttachmentHandler('#modalWriteComment');
        this.trixAttachmentHandlerSendMail = new TrixAttachmentHandler('#modalWriteEmail');
        jQuery('.activity-feed .comment').each((i, elem) => {
            this.trixAttachmentHandlersEditComment[jQuery(elem).data('id')] = new TrixAttachmentHandler('.comment[data-id="' + jQuery(elem).data('id') + '"] .comment-text-editor');
        })

        this.trixAttachmentHandlerNewComment.addEventListener();
        this.trixAttachmentHandlersEditComment.forEach((handler) => handler.addEventListener());
        this.trixAttachmentHandlerSendMail.addEventListener();

        jQuery('.action-phoneCall').on('click', (e) => this.showModalWriteNotes(e));
        jQuery('.action-appointmentOnsite').on('click', (e) => this.showModalWriteNotes(e));

        jQuery('#confirmModalWriteNotes').on('click', (e) => this.writeNotes({}));
        jQuery('.comment-text style').toArray().forEach(styleTag => {
            if(styleTag.sheet.cssRules) {
                [].slice.call(styleTag.sheet.cssRules).forEach(cssRule => { if(cssRule.selectorText) cssRule.selectorText = '.comment-text ' + cssRule.selectorText.split(',').join(', .comment-text ') })
            }
        })
    }
}
