import moment from 'moment';
import { Controller } from 'stimulus';
require('../js/fittext');

Number.prototype.floor = function (precision) { return Math.floor(Math.pow(10, precision) * this) / Math.pow(10, precision) }
Number.prototype.ceil = function (precision) { return Math.ceil(Math.pow(10, precision) * this) / Math.pow(10, precision) }
Number.prototype.round = function (precision) { return Math.round(Math.pow(10, precision) * this) / Math.pow(10, precision) }

function formatNumberToCurrency(number, currency = '€') {
    let nb = parseFloat(number).round(2);
    return (nb == parseInt(nb + '') ? parseInt(nb + '') : nb.toLocaleString('fr-FR', { minimumIntegerDigits: 1, minimumFractionDigits: 2 })) + ' ' + currency;
}

function getQuarterStartDate(d) {
    var qStart = new Date(d || new Date());
    qStart.setMonth(qStart.getMonth() - qStart.getMonth() % 3, 1);
    return qStart;
}

function getDaysSinceQuarterStart(d) {
    d = d || new Date();
    return Math.floor((d - getQuarterStartDate(d)) / 8.64e7);
}

const wedgingTypes = {
    without: 0,
    financed: 1,
    offered: 2,
};
const fundingPlans = {
    classic: 0,
    exaegis: 1,
    leaseCom: 2,
};

export default class extends Controller {
    static values = {
        quotationFlatTotal: Number,
        quotationMonthlyTotal: Number,
        quotationFlatMarginIko: Number,
        quotationMonthlyMarginIko: Number,
        oneShotPayBackPercentage: Number,
        leaseRates: Array
    };

    quotationTotal;
    quotationMarginIko;
    monthNumber = 36;
    wedgingType = 0;
    startingDate;
    validationDate;
    wedgingMonthDuration = 3;
    monthBilledCount = 0;
    daysSinceQuarterStart = 0;
    applicableRatesDefault;

    computeFundingPlan(planId) {
        let that = this;
        let plan = {
            id: planId,
            fundedAmount: this.quotationTotal,
            oneShotPayBackPercentage: this.oneShotPayBackPercentageValue,
            amountToReportLeaser: null,
            ikoPaidBackAmount: null,
            monthlyPayment: null,
            amountToReportLeaser: null,
            lease: null,
            serviceCost: null,
            wedgingAmount: null,
            totalMarginLeaser: null,
            computeFundedAmountSpecialCase() {
                if(this.id == fundingPlans.leaseCom && that.wedgingType == wedgingTypes.offered) {
                    const applicableRates = that.leaseRatesValue[fundingPlans.leaseCom][that.monthBilledCount][that.monthNumber];
                    const rateKey = Object.keys(applicableRates).sort((a, b) => parseInt(b) - parseInt(a)).find((threshold) => threshold < this.amountToReportLeaser);
                    this.fundedAmount = this.monthlyPayment * 100 / applicableRates[rateKey];
                }
                return this;
            },
            computeAmountToReportLeaser() {
                switch(this.id) {
                    case fundingPlans.exaegis:
                        this.amountToReportLeaser = this.fundedAmount * this.oneShotPayBackPercentage / 100;
                        this.serviceCost = (that.monthNumber > 0 ? (this.fundedAmount - this.amountToReportLeaser) / that.monthNumber : null).round(2);//.ceil(2);
                        break;
                    case fundingPlans.classic:
                    case fundingPlans.leaseCom:
                        this.amountToReportLeaser = this.fundedAmount;
                        break;
                }
                return this;
            },
            computeIkoPaidBackAmount() {
                let result = that.quotationTotal;
                if(that.wedgingType == wedgingTypes.offered) {
                    const applicableRates = that.leaseRatesValue[this.id][that.wedgingType == wedgingTypes.offered && this.id == fundingPlans.classic ? that.monthBilledCount : 0][that.monthNumber];
                    const rateKey  = Object.keys(applicableRates).sort((a, b) => parseInt(b) - parseInt(a)).find((threshold) => threshold < this.amountToReportLeaser);
                    const rateKeyDefault = Object.keys(that.applicableRatesDefault).sort((a, b) => parseInt(b) - parseInt(a)).find((threshold) => threshold < this.amountToReportLeaser);
        
                    switch(this.id) {
                        case fundingPlans.exaegis:
                            result += this.serviceCost * that.monthBilledCount;
                            result += (that.quotationTotal * (that.oneShotPayBackPercentageValue / 100) * (1 - (applicableRates[rateKey] / that.applicableRatesDefault[rateKeyDefault])));
                            break;
                        case fundingPlans.classic:
                            result += (that.quotationTotal * (1 - (applicableRates[rateKey] / that.applicableRatesDefault[rateKeyDefault])));
                            break;
                    }
                }
                this.ikoPaidBackAmount = result;
                return this;
            },
            computeLease() {
                let thresholdComparator = this.amountToReportLeaser;
                if(plan.id == fundingPlans.exaegis && that.wedgingType == wedgingTypes.offered) thresholdComparator = this.fundedAmount - thresholdComparator;

                const applicableRates = that.leaseRatesValue[planId][that.wedgingType == wedgingTypes.financed ? that.monthBilledCount : 0][that.monthNumber];
                const rateKey = Object.keys(applicableRates).sort((a, b) => parseInt(b) - parseInt(a)).find((threshold) => threshold < thresholdComparator);
                this.lease = (this.amountToReportLeaser * applicableRates[rateKey] / 100).round(2);
                return this;
            },
            computeMarginLeaser() {
                this.totalMarginLeaser = (this.ikoPaidBackAmount ?? this.fundedAmount) - (that.quotationTotal - that.quotationMarginIko);
                this.totalMarginLeaserPercentage = this.totalMarginLeaser / (that.quotationTotal - that.quotationMarginIko);
                return this;
            },
            computeMonthlyPayment() {
                switch(this.id) {
                    case fundingPlans.classic:
                    case fundingPlans.leaseCom:
                        this.monthlyPayment = this.lease;
                        break;
                    case fundingPlans.exaegis:
                        this.monthlyPayment = (this.lease + this.serviceCost).round(2);//.ceil(2);
                        break;
                }
                return this;
            },
            computeWedgingAmount() {
                switch (that.wedgingType) {
                    case wedgingTypes.financed:
                        const rateKeyDefault = Object.keys(that.applicableRatesDefault).sort((a, b) => parseInt(b) - parseInt(a)).find((threshold) => threshold < this.amountToReportLeaser);
                        this.wedgingAmount = this.lease / (that.applicableRatesDefault[rateKeyDefault] / 100) - this.amountToReportLeaser;
                        break;
                    case wedgingTypes.offered:
                        switch(this.id) {
                            case fundingPlans.classic:
                                this.wedgingAmount = this.fundedAmount - this.ikoPaidBackAmount;
                                break;
                            case fundingPlans.exaegis:
                                this.wedgingAmount = that.quotationMarginIko - this.totalMarginLeaser;
                                break;
                        }
                        break;
                }
                return this;
            },
            compute() {
                return this
                    .computeAmountToReportLeaser()
                    .computeIkoPaidBackAmount()
                    .computeLease()
                    .computeMonthlyPayment()
                    .computeFundedAmountSpecialCase()
                    .computeMarginLeaser()
                    .computeWedgingAmount();
            }
        }

        return plan.compute();
    }

    renderFundingPlans() {
        Object.values(fundingPlans).forEach((planId) => {
            let plan = this.computeFundingPlan(planId);
            let sliderColumn = jQuery('.segmented-slider-column[data-plan-id="' + planId + '"]');
            sliderColumn.find('.segmented-slider-cell:not(.blank)').toArray().forEach(cell => {
                let val = plan[jQuery(cell).data('name')];
                switch(jQuery(cell).data('name')) {
                    case 'totalMarginLeaser':
                        jQuery(cell).removeClass('danger success');
                        jQuery(cell).addClass(plan.totalMarginLeaserPercentage < 0.2 ? 'danger' : 'success');
                        jQuery(cell).text((isNaN(val) ? '-' : formatNumberToCurrency(val)) + ' (' + (isNaN(plan.totalMarginLeaserPercentage) ? '? %' : formatNumberToCurrency(plan.totalMarginLeaserPercentage * 100, '%')) + ')');
                        break;
                    case 'oneShotPayBackPercentage':
                        jQuery(cell).text(val ? formatNumberToCurrency(val, '%') : (isNaN(val) ? '-' : val));
                        break;
                    default:
                        jQuery(cell).text(val ? formatNumberToCurrency(val) : (isNaN(val) ? '-' : val));
                        break;
                }
            })
        });
    }

    computeMonthBilledCount() {
        this.startingDate = new Date(jQuery('#Quotation_wedgingStartDate').val());
        this.validationDate = new Date(jQuery('#Quotation_wedgingValidationDate').val());
        this.wedgingMonthDuration = jQuery('#Quotation_wedgingMonthDuration').val();
        if(this.startingDate && this.validationDate && this.wedgingMonthDuration) {
            this.daysSinceQuarterStart = getDaysSinceQuarterStart(this.validationDate);
            jQuery('#Quotation_quarterStartingDate').val(moment(getQuarterStartDate(this.validationDate), 'DD-MM-YYYY', true).format('YYYY-MM-DD'));
            jQuery('#Quotation_wedgingDurationFormatted').val((isNaN(this.daysSinceQuarterStart) ? '?' : this.daysSinceQuarterStart) + ' jours');
            this.monthBilledCount = parseInt(this.daysSinceQuarterStart < 15 ? 1 : this.daysSinceQuarterStart < 45 ? 2 : this.daysSinceQuarterStart < 59 ? 1 : 3);
            jQuery('#Quotation_monthBilled').val(this.monthBilledCount);
            this.renderFundingPlans();
        }
    }

    connect() {
        jQuery('#Quotation_monthCommitedNumber').on('change', (e) => {
            this.monthNumber = parseInt(jQuery('#Quotation_monthCommitedNumber').find(':selected').val());
            this.quotationTotal = this.quotationFlatTotalValue + this.quotationMonthlyTotalValue * this.monthNumber;
            this.quotationMarginIko = this.quotationFlatMarginIkoValue + this.quotationMonthlyMarginIkoValue * this.monthNumber;
            this.applicableRatesDefault = this.leaseRatesValue[0][0][this.monthNumber];
            this.renderFundingPlans();
        });
        jQuery('#Quotation_wedgingType').on('change', (e) => {
            this.wedgingType = parseInt(jQuery(e.target).val());
            jQuery('#Quotation_wedgingStartDate').attr('disabled', this.wedgingType == wedgingTypes.without); 
            jQuery('#Quotation_wedgingValidationDate').attr('disabled', this.wedgingType == wedgingTypes.without); 
            this.renderFundingPlans();
        });
        jQuery('#Quotation_wedgingValidationDate').on('change', (e) => {
            this.computeMonthBilledCount();
            this.renderFundingPlans();
        });
        jQuery('#Quotation_wedgingStartDate').on('change', (e) => {
            this.computeMonthBilledCount();
            this.renderFundingPlans();
        });
        jQuery('#Quotation_fundingFormula').on('change', (e) => {
            jQuery('.segmented-slider-shadow').css('--choice-index', jQuery(e.target).val());
        });

        this.monthNumber = parseInt(jQuery('#Quotation_monthCommitedNumber').find(':selected').val());
        this.wedgingType = parseInt(jQuery('#Quotation_wedgingType').val());
        this.quotationTotal = this.quotationFlatTotalValue + this.quotationMonthlyTotalValue * this.monthNumber;
        this.quotationMarginIko = this.quotationFlatMarginIkoValue + this.quotationMonthlyMarginIkoValue * this.monthNumber;
        this.applicableRatesDefault = this.leaseRatesValue[0][0][this.monthNumber];
        this.computeMonthBilledCount();

        jQuery('.segmented-slider-cell:not(.blank)').fitText(1.2, { minFontSize: 10, maxFontSize: 16 });
    }
}
