import { Controller } from 'stimulus';

export default class extends Controller {
    static values = {
        inputName: String,
        clientId: Number
    }

    connect() {
        jQuery('[name="' + this.inputNameValue + '"]').select2({
            theme: 'bootstrap',
            placeholder: 'Rechercher un contact',
            allowClear: true,
            data: [],
            ajax: {
                transport: (params, success, failure) => {
                    return jQuery.get(this.clientIdValue ? '/api/clients/' + this.clientIdValue + '/contacts/' + (params.data.term ?? '') : '/api/contacts/' + params.data.term + '/' + (params.data.page || 0))
                        .done(success)
                        .fail(failure);
                },
                delay: 250,
                processResults: function (data) {
                    return {
                      results: data,
                      pagination: {
                        more: data && data.length == 50
                      }
                    };
                }
            },
        })
        jQuery('[name="' + this.inputNameValue + '"]').val(null).trigger('change');
    }
}