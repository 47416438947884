import 'moment';
import { Controller } from 'stimulus';

const refreshInterval = 10000;

export default class extends Controller {
    intervals = [];

    refreshDataProjectsCompletion(args) {
        let url = '/api/projects/completion';
        
        jQuery.get(url, {}, response => {
            const res = JSON.parse(response);
            jQuery('#mean-progress-bar .common-progress-bar').attr('data-percentage', Math.round(100 * res.projects.map(e => e.completion).reduce((a, b) => a + b) / res.projects.length) / 100)
            if (res != null) {
                jQuery('#projects-progress-bars').html(res.content)
            }   
        });
    }

    setIntervalImmediately(func, interval, ...args) {
        func(args);
        return setInterval(func, interval, args);
    }

    initRefreshDataListener() {
        this.intervals.push(this.setIntervalImmediately((args) => this.refreshDataProjectsCompletion(args), refreshInterval));
    }

    connect() {
        jQuery('.main-header').children().first().after(jQuery('#inner-header').last());

        jQuery('#progress-bars').on('mouseenter', () => jQuery('#projects-progress-bars').css('display', 'table') && jQuery('#progress-bars').css('box-shadow', '2px 0px 7px var(--gray-400)'))
        jQuery('#progress-bars').on('mouseleave', () => jQuery('#projects-progress-bars:hover').length > 0 ? '' : jQuery('#projects-progress-bars').css('display', 'none') && jQuery('#progress-bars').css('box-shadow', 'unset'));
        jQuery('#projects-progress-bars').on('mouseleave', () => jQuery('#progress-bars:hover').length > 0 ? '' : jQuery('#projects-progress-bars').css('display', 'none') && jQuery('#progress-bars').css('box-shadow', 'unset'));
        this.initRefreshDataListener();
    }

    destroy() {
        this.intervals.forEach(interval => window.clearInterval(interval));
    }
}
