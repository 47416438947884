import { Controller } from 'stimulus';

export default class extends Controller {
    static values = {
        urlSave: String
    }

    updateStockInputsCount(target) {
        const productStockInputs = jQuery(target).parentsUntil('#purchase-order').last();
        const productId = productStockInputs.attr('data-product');
        const newCount = productStockInputs.children().toArray().map(div => jQuery(div).find('.checkbox:checked').length).reduce((a, b) => a + b, 0);
        let orderLineQuantitySpan = jQuery(`.order-line[data-product="${productId}"]`).find('.stock-inputs-count-order-line');
        orderLineQuantitySpan.text(newCount);
        if(orderLineQuantitySpan.parent().attr('data-quantity') < newCount) {
            orderLineQuantitySpan.parent().addClass('text-danger');
            orderLineQuantitySpan.closest('.order-line').find('.actions .dropdown-item').attr('disabled', 'disabled');
            jQuery('.content-header .global-actions .btn').addClass('disabled');
        } else {
            orderLineQuantitySpan.parent().removeClass('text-danger');
            orderLineQuantitySpan.closest('.order-line').find('.actions .dropdown-item').removeAttr('disabled');
            jQuery('.content-header .global-actions .btn').removeClass('disabled');
        }
    }

    saveStockInputs(callback) {
        let productsStockInputs = {};
        jQuery('.product-stock-inputs').each((i, psi) => { productsStockInputs[jQuery(psi).attr('data-product')] = jQuery(psi).find('.checkbox:checked').toArray().map(c => jQuery(c).val()) });
        jQuery.post(this.urlSaveValue, {
            products: productsStockInputs
        }).then(callback).catch(e => {
            let error = JSON.parse(e.responseText);
            switch(error.type) {
                case 'unknow_product':
                    alert(error.message + '\nIl y a une erreur dans la requête qui provient peut être de l\'application client et non pas de la saisie effectuée')
                    break;
                case 'too_many_products':
                    jQuery(`.order-line[data-product="${error.id}"]`).tooltip({placement: 'top',trigger: 'manual', title: error.message}).tooltip('show');
                    jQuery(`.order-line[data-product="${error.id}"]`).on('mouseout', function() { jQuery(this).tooltip('hide'); });
                    break;
            }
        });
    }

    connect() {
        jQuery('.order-line').on('click', (e) => {
            let orderLine = jQuery(e.target).parentsUntil('tbody').last()
            const productId = orderLine.attr('data-product');
            const isLineAlreadySelected = orderLine.hasClass('selected');
            jQuery('.order-line.selected').removeClass('selected');
            jQuery(`.product-stock-inputs`).hide();
            if(!isLineAlreadySelected) {
                orderLine.addClass('selected')
                setTimeout(() => jQuery(`.product-stock-inputs[data-product=${productId}]`).show(400), 50)
            }
        });

        jQuery('.checkbox-all').on('change', (e) => {
            jQuery(e.target).parentsUntil('.content-panel').last().find('.checkbox').prop('checked', jQuery(e.target).prop('checked'));
            this.updateStockInputsCount(e.target);
        });

        jQuery('.checkbox').on('change', (e) => {
            this.updateStockInputsCount(e.target);
        });

        jQuery('#save').on('click', () => this.saveStockInputs(urlCallback => { document.location.href = urlCallback; }))
        jQuery('#saveAndContinue').on('click', () => this.saveStockInputs(_ => { document.location.reload(); }))
    }
}
