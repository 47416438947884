import { Controller } from 'stimulus';
import jQuery from 'jquery';

export default class extends Controller {

    connect() {
        jQuery("#login-button").on('click', function(event){
            //event.preventDefault();
          
          jQuery('form').fadeOut(500);
          jQuery('.wrapper').addClass('form-success');
       });
    }
}
