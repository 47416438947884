import { Controller } from 'stimulus';

export default class extends Controller {
    isParentExpanded = true;
    isExpanded = true;

    static values = {
        childEntitiesIds: Array
    }

    connect() {
        jQuery(this.element).find('td:first-child').css('cursor', 'pointer');
        jQuery(this.element).on('click', () => {
            this.isExpanded = !this.isExpanded;
            this.toggleChildsExpansion();
            console.log(this.childEntitiesIdsValue);

        });
        jQuery(this.element).on('toggle-node-expansion', (e) => {
            e.stopPropagation();
            this.isParentExpanded = e.detail.isExpanded;
            this.toggleChildsExpansion();
        })
    }

    toggleChildsExpansion() {
        jQuery(this.element).find('.fa-arrow-circle-down')[this.isExpanded ? 'removeClass' : 'addClass' ]('fa-rotate-270');
        this.childEntitiesIdsValue.forEach(id => {
            let child = jQuery(this.element).parent().children('[data-id="' + id + '"]');
            child[this.isExpanded && this.isParentExpanded ? 'show' : 'hide' ](200);
            child.get(0).dispatchEvent(
                new CustomEvent('toggle-node-expansion', { detail: { isExpanded: this.isParentExpanded && this.isExpanded } })
            );
        });
    }
}
