import { Controller } from 'stimulus';

export default class extends Controller {
    writeNotesUrl;

    writeNotes(data) {
        data.content = jQuery('#modalWriteNotesContent').val();

        jQuery.post(this.writeNotesUrl, data).then(() => {
            document.location.reload();
        }).catch(e => {
            jQuery('#modalWriteNotes').modal('hide');
        });
    }

    showModalWriteNotes(e) {
        e.preventDefault();
        e.stopPropagation();

        this.writeNotesUrl = jQuery(e.target).attr('href');

        jQuery('#modalWriteNotes').modal('show');
    }

    connect() {
        jQuery('.project').each(function() {
            const isExpanded = window.sessionStorage.getItem('project-' + jQuery(this).attr('data-id') + '-is-expanded');
            let collapsibleElement = jQuery(this).children().first().find('.fa-times');
            if(isExpanded !== null) {
                if((isExpanded === 'true') == collapsibleElement.hasClass('collapsed'))
                    collapsibleElement.trigger('click')
            }
         });

        jQuery('.project .fa-times[data-toggle="collapse"]').on('click', (e) => {
            const projectIdClicked = jQuery(e.target).parentsUntil('tbody').last().attr('data-id');
            window.sessionStorage.setItem('project-' + projectIdClicked + '-is-expanded', jQuery(e.target).hasClass('collapsed'))
        });

        jQuery('.action-sendMail').on('click', (e) => this.showModalWriteNotes(e));
        jQuery('.action-phoneCall').on('click', (e) => this.showModalWriteNotes(e));
        jQuery('.action-sendSMS').on('click', (e) => this.showModalWriteNotes(e));
        jQuery('.action-rdvSite').on('click', (e) => this.showModalWriteNotes(e));

        jQuery('#confirmModalWriteNotes').on('click', (e) => this.writeNotes({}));

        jQuery('tr.sub-entity-row .field-url').each((i, field) => jQuery(field).children('a').attr('href', jQuery(field).parentsUntil('tbody').find('.action-progress').attr('href')));
        jQuery('tr.sub-entity-row .field-url a').attr('target', null);
    }
}
