import { Controller } from 'stimulus';

export default class extends Controller {

    static values = {
        color: String
    }

    connect() {
        setTimeout(() => jQuery(this.element).closest('.field-warning').next().css('--warning-color', this.colorValue), 100)
    }
}

