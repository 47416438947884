import { Controller } from 'stimulus';
import MailPreview from '../js/mail-previews';
import PagesModal from '../js/pages-modal';
import TrixAttachmentHandler from '../js/trix-attachment-handler';

export default class extends Controller {

	form;
	formerProjectManager;
	newProjectManager;
	pagesModal;
	serviceId;
    trixAttachmentHandlerPages;
	validated;

	showModalPreviewMails() {
		let data = Object.fromEntries(new FormData(document.getElementById(this.form.attr('id'))));
		if(this.serviceId) data['Service[id]'] = this.serviceId;
		delete data.referrer;
        this.form.find('input[disabled], select[disabled]').each((_, element) => data[jQuery(element).attr('name')] = jQuery(element).val());

		this.pagesModal.clearPages();
		MailPreview.getServiceProjetManagerAssignationPreview(this.newProjectManager, data).then((pages) => {
			this.pagesModal.addPages(...pages).show();
			this.trixAttachmentHandlerPages.addEventListener();
		});
	}


	pushPreviewsToData(data, previewsJquery, defaultAttachmentsElems, attachments) {
        previewsJquery.each((_, input) => {
            let name = jQuery(input).prop('name');
            const path = name.split('_');
            if(!data.previews[path[0]]) data.previews[path[0]] = {};
            let dataEntry = data.previews[path[0]];
            if(!dataEntry[path[1]]) dataEntry[path[1]] = {};
            dataEntry = dataEntry[path[1]];
            dataEntry['content'] = jQuery(input).val();

            if(defaultAttachmentsElems)
                dataEntry['defaultAttachmentsIds'] = defaultAttachmentsElems.filter((elem) => jQuery(elem).closest('.initial-attachments').data('key') == name).map((elem) => elem.id);

            Object.keys(attachments ?? {}).forEach((fileHash, i) => {
                if(!dataEntry.files) dataEntry.files = [];
                dataEntry.files.push(attachments[fileHash]);
            }, self);
        });
    }

	insertPreviewModal() {
		this.pagesModal = new PagesModal('Notification de l\'assignation du service à un coordinateur');
		this.trixAttachmentHandlerPages = new TrixAttachmentHandler('#' + this.pagesModal.getHTMLId());
		this.form.on('submit', (e) => {
			this.newProjectManager = jQuery('[name="Service[projectManager]"]').val();
			if(!this.validated && this.newProjectManager && (this.newProjectManager != this.formerProjectManager || !this.serviceId)) {
				let triggeringButton;
				if(!e.originalEvent || !e.originalEvent.submitter) triggeringButton = jQuery('.page-actions').find("button[type=submit]:focus");
				else triggeringButton = jQuery(e.originalEvent.submitter);
				e.preventDefault();
				e.stopImmediatePropagation();
				this.showModalPreviewMails();
		
				this.pagesModal.onConfirm(() => this.confirmPreviews(triggeringButton));
				jQuery('#' + this.pagesModal.getHTMLId()).on('hidden.bs.modal', () => {
					this.pagesModal.onConfirm(null);
				});
				return false;
			}
		});
	}

	isValidHttpUrl(string) {
		let url;
		try {
		  url = new URL(string);
		  return true;
		} catch (_) {
		  return false;  
		}
	}

	confirmPreviews(triggeringButton) {
		this.validated = true;
		var formData = new FormData(this.form[0]);
		jQuery('#' + this.pagesModal.getHTMLId() +' .mail-preview-page input[type="hidden"]:not([disabled="disabled"]').each(async (_, input) => {
			const name = jQuery(input).prop('name');
			const path = name.split('_');
			formData.append(`previews[${path[0]}][${path[1]}][content]`, jQuery(input).val());

			let defaultAttachmentsElems = jQuery('#' + this.pagesModal.getHTMLId() + ' .mail-preview-page .initial-attachments > div').toArray();
			if(defaultAttachmentsElems)
				defaultAttachmentsElems.filter((elem) => jQuery(elem).closest('.initial-attachments').data('key') == name).forEach((elem, i) =>
					formData.append(`previews[${path[0]}][${path[1]}][defaultAttachmentsIds][${i}]`, elem.id)
				);

			let attachments = this.trixAttachmentHandlerPages.getAttachments();
			Object.keys(attachments ?? {}).forEach((fileHash, i) => {
				formData.append(`previews[${path[0]}][${path[1]}][files][${i}]`, attachments[fileHash]);
			});
		});
		formData.append(triggeringButton.attr('name'), triggeringButton.val());
		jQuery.ajax({
			headers: {'X-Requested-With': 'HttpRequest'},
			method: "POST",
			data: formData,
			processData: false,
			contentType: false
        }).then((newPage) => {
			if(this.isValidHttpUrl(newPage)) document.location = newPage;
			else { document.open(); document.write(newPage); document.close(); }
		});
	}

	connect() {
		this.form = jQuery('form[name="Service"]');
		this.formerProjectManager = jQuery('[name="Service[projectManager]"]').val();

		if(this.form) {
			this.serviceId = jQuery('body').attr('id').split('-').reverse()[0];
			jQuery.get('/api/service/' + (this.serviceId ? this.serviceId + '/' : '') + 'substep/project-manager-assignation').then(subStep => {
				if(subStep && subStep.id) {
					this.subStep = subStep;
					this.insertPreviewModal();
				}
			})
		}
	}
}