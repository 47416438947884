import { Controller } from 'stimulus';

export default class extends Controller {
    static values = {
        inputName: String,
        optionsTree: Object,
        title: String
    }

    printOptionsTree(optionsTree, pathPrefix = '') {
        let res = [];
        Object.keys(optionsTree).forEach(key => {
            if(optionsTree[key] instanceof Object) {
                let subOptions = this.printOptionsTree(optionsTree[key], pathPrefix + key + '.');
                res.push(`<optgroup label="${optionsTree[key]['_label']}">${subOptions.filter(so => so.startsWith('<option')).join('')}</optgroup>`)
                subOptions.filter(so => so.startsWith('<optgroup')).forEach(og => res.push(og));
            } else if(typeof optionsTree[key] === 'string' && key != '_label')
                res.push(`<option value="{{ ${pathPrefix + key} }}">${optionsTree[key]}</option>`);
        })
        return res;
    }
    
    connect() {
        try { if(navigator && navigator.permissions) navigator.permissions.query({name: "clipboard-write"}); } catch(e) {}
        let formWidget = jQuery('[name="' + this.inputNameValue + '"]').parentsUntil('.form-group').last();
        let widget = formWidget.prepend(`
            <div class="placeholder-picker-widget">
                <label>${this.titleValue}</label>
                <select class="placeholder-picker form-select select2">
                    ${this.printOptionsTree(this.optionsTreeValue).sort((a, b) => a.localeCompare(b)).join('')}
                </select>
                <div class="widget-message">Copié dans le presse-papier</div>
            </div>
        `).children().first();
        widget.find('select.placeholder-picker').select2({
            theme: 'bootstrap',
            matcher: (params, data) => this.modelMatcher(params, data)
        });
        widget.find('.placeholder-picker').on('change.select2', function() {
            navigator.clipboard.writeText(widget.find('.placeholder-picker option:selected').val()).then(function() {
                widget.find('.widget-message').addClass('copied');
                setTimeout(() => widget.find('.widget-message').removeClass('copied'), 2000);
            })
        })
    }

    modelMatcher (params, data) {
        data.parentText = data.parentText || "";
      
        // Always return the object if there is nothing to compare
        if (jQuery.trim(params.term) === '') {
          return data;
        }
      
        // Do a recursive check for options with children
        if (data.children && data.children.length > 0) {
          // Clone the data object if there are children
          // This is required as we modify the object to remove any non-matches
          var match = jQuery.extend(true, {}, data);
      
          // Check each child of the option
          for (var c = data.children.length - 1; c >= 0; c--) {
            var child = data.children[c];
            child.parentText += data.parentText + " " + data.text;
      
            var matches = this.modelMatcher(params, child);
      
            // If there wasn't a match, remove the object in the array
            if (matches == null) {
              match.children.splice(c, 1);
            }
          }
      
          // If any children matched, return the new object
          if (match.children.length > 0) {
            return match;
          }
      
          // If there were no matching children, check just the plain object
          return this.modelMatcher(params, match);
        }
      
        // If the typed-in term matches the text of this term, or the text from any
        // parent term, then it's a match.
        var original = (data.parentText + ' ' + data.text).toUpperCase();
        var term = params.term.toUpperCase();
      
      
        // Check if the text contains the term
        if (original.indexOf(term) > -1) {
          return data;
        }
      
        // If it doesn't contain the term, don't return anything
        return null;
    }
}
