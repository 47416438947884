import jquery from 'jquery';
import { Controller } from 'stimulus';

function formatNumberToCurrency(number, currency = '€') {
    let nb = Math.round(parseFloat(number) * 100) / 100;
    return (nb == parseInt(nb + '') ? parseInt(nb + '') : nb.toLocaleString('fr-FR', { minimumIntegerDigits: 1, minimumFractionDigits: 2 })) + ' ' + currency;
}

function ucfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default class extends Controller {
    static values = {
        productsByFamily : Object
    }

    totals;
    sitesLines = {};
    siteTotals = {};
    billingType = 0;
    monthNumber = 0;
    productsById;
    productsByFamily;

    renderQuotationTotals() {
        let totalLine = jQuery('.content-panel-footer .quotation-total-line');
        for(let key in this.totals) {
            switch(key) {
                case 'ha_flat_rate':
                case 'ha_monthly':
                case 'sales_flat_rate':
                case 'sales_monthly':
                case 'ca':
                case 'iko_margin':
                    totalLine.find('[data-name="' + key + '"] input').val(formatNumberToCurrency(this.totals[key]));
                    break;
                case 'margin':
                    totalLine.find('[data-name="' + key + '"]').parent()[parseInt(this.totals[key]) < 20 ? 'addClass' : 'removeClass']('margin-insufficient');
                    totalLine.find('[data-name="' + key + '"] input').val(this.totals[key] + ' %');
                    break;
                case 'quantity':
                    totalLine.find('[data-name="' + key + '"] input').val(this.totals[key]);
                    break;
            }
            
        }
    }

    salesModelUpdate() {
        this.billingType = jQuery('[name="Quotation[billingType]"]').find(':selected').val();
        if(this.billingType == 0) {
            this.monthNumber = jQuery('[name="Quotation[monthNumber]"]').val();
            jQuery('[name="Quotation[monthNumber]"]').attr('disabled', false);
        } else {
            this.monthNumber = jQuery('[name="Quotation[monthCommitedNumber]"]').val();
            jQuery('[name="Quotation[monthCommitedNumber]"]').attr('disabled', false);
        }
        this.element.dispatchEvent(new CustomEvent('sales-model-update', { detail: { billingType: this.billingType, monthNumber: this.monthNumber } }));
    }

    connect() {
        this.productsByFamily = Object.fromEntries(Object.keys(this.productsByFamilyValue).map(
            familyId => [familyId, this.productsByFamilyValue[familyId].map((pList) => Object.values(pList)).flat()]
        ));
        this.productsById = {};
        Object.values(this.productsByFamily).forEach(pList => pList.forEach(p => { if(p.id) this.productsById[p.id] = p; }));

        jQuery('.sales-model-content .content-panel-header').on('click', (e) => jQuery(e.target).parent().find('.content-panel-body').toggle('display'));
        jQuery('.add-custom-product-action').on('click', (e) => {
            jQuery.get('/api/quotation/custom-product/form').done(formPage => {
                jQuery.noConflict(false);
                jQuery('#modal-product-form .modal-body').html(formPage);
                jQuery('#modal-product-form').modal('show');
                jQuery('#modal-product-form button[form="new-Product-form"]').on('click', () => jQuery.ajax({
                    type: "POST",
                    url: jQuery('#modal-product-form #new-Product-form').attr('action'),
                    data: jQuery('#modal-product-form #new-Product-form').serialize(),
                    encode: true,
                  }).done(() => document.location.reload()));
            });
        });

        jQuery('[name="Quotation[monthNumber]"]').on('change', () => this.salesModelUpdate());
        jQuery('[name="Quotation[monthCommitedNumber]"]').on('change', () => this.salesModelUpdate());
        jQuery('[name="Quotation[billingType]"]').on('change', (e) => {
            jQuery('[name="Quotation[monthNumber]"]').attr('disabled', true);
            jQuery('[name="Quotation[monthCommitedNumber]"]').attr('disabled', true);
            this.salesModelUpdate();
        });

        jQuery(this.element).find('.quotation-site-lines').on('totals-update', (e) => {
            this.sitesLines[e.target.id] = e.detail.lines;
            this.siteTotals[e.target.id] = e.detail.totals;
            this.siteTotals[e.target.id].margin = parseFloat(this.siteTotals[e.target.id].margin);
            this.totals = Object.values(this.siteTotals).reduce((prev, current) => {
                let res = jQuery.extend({}, prev);
                for (let key in current) {
                    if(key == 'margin') continue;
                    else if(current.hasOwnProperty(key)) res[key] = (res[key] ?? 0) + current[key];
                }
                return res;
            })
            this.totals.margin = Math.round(10000 * this.totals.ca / (this.totals.ca - this.totals.iko_margin)) / 100 - 100
            this.totals.margin = this.totals.margin.toLocaleString('fr-FR');
            this.renderQuotationTotals();
        });

        jQuery('form#edit-Quotation-form').on('submit', (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            let formData = new FormData();
            formData.append('Quotation[_token]', jQuery(e.target).find('[name="Quotation[_token]"]').val());
            formData.append('ea[editForm][btn]', jQuery(e.originalEvent.submitter).val());
            formData.append('Quotation[billingType]', jQuery(e.target).find('[name="Quotation[billingType]"]').find(':selected').val());
            if(!jQuery(e.target).find('[name="Quotation[monthNumber]"]').attr('disabled')) formData.append('Quotation[monthNumber]', jQuery(e.target).find('[name="Quotation[monthNumber]"]').val());
            if(!jQuery(e.target).find('[name="Quotation[monthCommitedNumber]"]').attr('disabled')) formData.append('Quotation[monthCommitedNumber]', jQuery(e.target).find('[name="Quotation[monthCommitedNumber]"]').find(':selected').val());
            let formProperties = ['product', 'quantity', 'margin', 'note', 'productFlatPrice', 'productMonthlyPrice'];
            let formProductProperties = ['name', 'flatPrice', 'monthlyPrice'];
            Object.values(this.sitesLines).forEach((siteLines, i) => {
                Object.values(siteLines).forEach((line, j) => {
                    formProperties.forEach((property) => {
                        if(line[property] != null) formData.append('Quotation[sites][' + i + '][siteLines][' + j + '][' + property + ']', line[property])
                    });
                    formProductProperties.forEach((property) => {
                        const propertyPath = 'Quotation[sites][' + i + '][siteLines][' + j + '][product' + ucfirst(property)  + ']';
                        if(!formData.has(propertyPath)) formData.set(propertyPath, this.productsById[line.product][property])
                    });
                });
            });
            jQuery.ajax({
                url: '',
                data: formData,
                processData: false,
                contentType: false,
                type: 'POST',
            }).then((newPage) => { document.open(); document.write(newPage); document.close(); });
		});

        this.salesModelUpdate();
    }
}
