import MailPreview from './mail-previews';
import PagesModal from './pages-modal';
import TrixAttachmentHandler from './trix-attachment-handler';

export default class CustomMailPreview extends MailPreview {
    title;
    getServiceId;
    customEmailModal;
    trixAttachmentHandlerCustomEmail;

    constructor(title, getServiceId) {
        super();
        this.title = title;
        this.getServiceId = getServiceId;
        this.customEmailModal = new PagesModal(this.title);
        this.customEmailModal.onConfirm(() => this.sendCustomEmail());
        this.trixAttachmentHandlerCustomEmail = new TrixAttachmentHandler('#' + this.customEmailModal.getHTMLId());
    }

    enable(buttonSelector) {
        jQuery('#' + this.customEmailModal.getHTMLId()).find('#nextModalSubStep').on('click', () => this.loadCustomEmailPage());
        jQuery(buttonSelector).on('click', (e) => this.showPageModailCustomEmail(e));
    }

    loadCustomEmailPage() {
        let sendTo = jQuery('.select-mail-template-form').find('[name="MailTemplateStep[sendTo][]"]').val();
        if(!sendTo.length) { return this.customEmailModal.changeModalPage('prev'); }

        let mailTemplateId = jQuery('.select-mail-template-form').find('[name="MailTemplateStep[mailTemplate]"]').val();
        let serviceId = jQuery('.select-mail-template-form').find('[name="MailTemplateStep[service_id]"]').val();
        if(mailTemplateId) {
            jQuery.get('/api/mail-template/' + mailTemplateId + "/" + serviceId).done(res => {
                jQuery('#' + this.customEmailModal.getHTMLId()).find('.custom-email-page').html(this.getCustomEmailPreview(sendTo, res));
                this.trixAttachmentHandlerCustomEmail.addEventListener();
            }).fail(() => {});
        } else {
            jQuery('#' + this.customEmailModal.getHTMLId()).find('.custom-email-page').html(this.getCustomEmailPreview(sendTo));
            this.trixAttachmentHandlerCustomEmail.addEventListener();
        }
    }

    sendCustomEmail() {
        var data = new FormData(jQuery('#' + this.customEmailModal.getHTMLId() + ' #customEmailForm').get(0));
        let attachments = this.trixAttachmentHandlerCustomEmail.getAttachments() ?? {};

        if(jQuery('#' + this.customEmailModal.getHTMLId() + ' #customEmailForm .initial-attachments'))
            jQuery('#' + this.customEmailModal.getHTMLId() + ' #customEmailForm .initial-attachments > div').get().forEach((elem, i) => data.append('defaultAttachmentsIds[' + i + ']', elem.id));

        Object.keys(attachments).forEach((fileHash, i) => {
            data.append('files[' + i + ']', attachments[fileHash]);
        }, this);
        data.append('serviceId', this.serviceId);
        jQuery.ajax({
            type: "POST",
            url: "/api/mail-template/send/custom",
            data: data,
            processData: false,
            contentType: false
        }).always(() => { document.location.reload() });
	}

    showPageModailCustomEmail(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.serviceId = this.getServiceId(e);
        jQuery.get('/api/service/' + this.serviceId + '/mail-template/form').done(formPage => {
            this.customEmailModal
                .clearPages()
                .addPages(formPage, '<div class="custom-email-page"></div>')
                .show();
        });
    }


    getSimpleMailPreview(mailTemplate, sendTo) {
        return `
            <div class="${MailPreview.PREVIEW_PAGE_CLASS}">
                <p class="page-title">Le mail ${mailTemplate.title ?? ''} sera envoyé à :</p>
                <p class="page-subtitle">${sendTo.join(', ')}</p>
                <p class="page-legend">La signature du coordinateur de service sera ajouté au mail lors de l'envoi.<br/>Pour ajouter des images, veuillez utiliser uniquement le bouton dédié.</p>
                <form id="customEmailForm">
                    ${mailTemplate.id ? `<input type="hidden" name="templateId" value="${mailTemplate.id}"/>` : ''}
                    <div data-controller="insert-image-trix-editor" data-insert-image-trix-editor-input-name-value="body" style="display:none"></div>
                    <div class="field-text form-group">
                        <label for="mailTemplateSubject" class="form-control-label required" style="flex: 0;white-space: nowrap;">Sujet :</label>
                        <div class="form-widget" style="flex:1;">
                            <input type="text" id="mailTemplateSubject" name="subject" class="form-control" style="width: 100%;max-width: unset;" value="${mailTemplate.subject ?? ''}"/>
                        </div>
                    </div>
                    <input id="sendTo_${mailTemplate.id}" type="hidden" name="sendTo" value="${sendTo.join(';')}">
                    ${mailTemplate.attachments
                        ? `<div class="initial-attachments"><span>Pièces-jointes par défault : </span>${mailTemplate.attachments.map((attachment) =>
                            `${TrixAttachmentHandler.getAttachmentTag(attachment.id, attachment)}`
                        ).join('')}</div>` : ''}
                    <input id="mailTemplate_${mailTemplate.id}" type="hidden" name="body" autocomplete="off">
                    <div class="editor-attachments"><span>Autres pièces-jointes (max 12 Mo par fichier) :</span></div>
                    <trix-editor input="mailTemplate_${mailTemplate.id}" rows="10"></trix-editor>
                </div>
                <script>jQuery('.${MailPreview.PREVIEW_PAGE_CLASS} trix-editor[input="mailTemplate_${mailTemplate.id}"]').get(0).editor.insertHTML(\`${mailTemplate.body ?? ''}\`)</script>
            </div>
        `;
    }

    getCustomEmailPreview(sendTo, mailTemplate) {
        return this.getSimpleMailPreview(mailTemplate ?? { id: 0 }, sendTo);
    }
}