import { Chart, registerables } from 'chart.js';
import { Controller } from 'stimulus';

const refreshInterval = 10000;

export default class extends Controller {
    preConnectCallback = (e) => this._onPreConnect(e);
    connectCallback = (e) => this._onConnect(e);

    chart;
    intervals = [];

    refreshData(args) {
        let url = args[0];
        let chart = args[1];
        
        jQuery.get(url, {}, response => {
            const res = JSON.parse(response);
            const chartData = chart.data;
            if (res.data != null && chartData.datasets.length > 0 && res.data.length <= chartData.datasets.length) {        
                chartData.labels = res.labels;
                for (var index = 0; index < res.data.length; ++index) {
                    chartData.datasets[index].data = res.data[index];
                }
              chart.update();
            }
        });
    }

    initRefreshDataListener() {
        switch(this.element.parentElement.id) {
            case 'bar-chart-client_support-tickets':
                this.intervals.push(this.setIntervalImmediately((args) => this.refreshData(args), refreshInterval, '/api/client/support-tickets/top/5', this.chart));
                break
            case 'bar-chart-project-managers-projects':
                this.chart.data.datasets[0].label = 'Nombre de projets en retard';
                this.chart.data.datasets[1].label = 'Nombre de projets en cours';
                this.intervals.push(this.setIntervalImmediately((args) => this.refreshData(args), refreshInterval, '/api/project/project-managers/20', this.chart));
                break
            case 'line-chart-support-tickets':
                this.intervals.push(this.setIntervalImmediately((args) => this.refreshData(args), refreshInterval, '/api/support-tickets/completed', this.chart));
                break;
            case 'doughnut-chart':
                var chartData = this.chart.data;
                chartData.labels = ['abcdefgh', ''];
                chartData.datasets[0].data = [6, 4];
                chartData.datasets[0].data = [6, 4];
                chartData.datasets[1].data = [4, 6];
                this.chart.options.plugins.legend.display = false;

                this.chart.options.plugins.beforeDraw = (chart) => {
                    const ctx = chart.ctx;
                    const {top, left, width, height} = chart.chartArea;
                    const x = left + width / 2;
                    const y = top + height / 2;
                    ctx.fillText('dskdj', x, y, 100)
                  }
                this.chart.update();
                break;
        }
    }

    setIntervalImmediately(func, interval, ...args) {
        func(args);
        return setInterval(func, interval, args);
    }

    connect() {
        Chart.register(...registerables);
        Chart.defaults.color = '#FFFFFFCC'
        this.element.addEventListener('chartjs:pre-connect', this.preConnectCallback);
        this.element.addEventListener('chartjs:connect', this.connectCallback);
    }

    disconnect() {
        this.intervals.forEach(interval => window.clearInterval(interval))
        // You should always remove listeners when the controller is disconnected to avoid side effects
        this.element.removeEventListener('chartjs:pre-connect', (e) => this.preConnectCallback);
        this.element.removeEventListener('chartjs:connect', (e) => this.connectCallback);
    }

    _onPreConnect(event) {
        // The chart is not yet created
    }

    _onConnect(event) {
        // The chart was just created
        this.chart = event.detail.chart;
        
        this.initRefreshDataListener();
    }
}