import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {

    static targets = [ "client" ]

    beforeValidation(event) {
        $(event.target).attr('href', $(event.target).attr('href') + "&client=" + this.clientTarget.value);
        window.location.replace($(event.target).attr('href'));
    }
}
