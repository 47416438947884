import { Controller } from 'stimulus';
import { Chart } from 'chart.js';

const totalAnimationDuration = 1000;

export default class extends Controller {
    chart;
    
    width;
    height;
    gradient;

    getGradient(context) {
        const chart = context.chart;
        const {ctx, chartArea} = chart;

        if (!chartArea) {
            // This case happens on initial chart load
            return null;
        }
        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (this.gradient === null || this.width !== chartWidth || this.height !== chartHeight) {
            // Create the gradient because this is either the first render
            // or the size of the chart has changed
            this.width = chartWidth;
            this.height = chartHeight;
            this.gradient = ctx.createLinearGradient(0, 0, 0, chartHeight * 2);
            this.gradient.addColorStop(0, "#25C8E588");
            this.gradient.addColorStop(1, "#25C8E501");
        }

        return this.gradient;
    }

    connect() {
        this.element.addEventListener('chartjs:pre-connect', (e) => this._onPreConnect(e));
        this.element.addEventListener('chartjs:connect', (e) => this._onConnect(e));
    }

    disconnect() {
        this.intervals.forEach(interval => window.clearInterval(interval))
        // You should always remove listeners when the controller is disconnected to avoid side effects
        this.element.removeEventListener('chartjs:pre-connect', (e) => this._onPreConnect(e));
        this.element.removeEventListener('chartjs:connect', (e) => this._onConnect(e));
    }

    _onPreConnect(event) {
        // The chart is not yet created
    }

    _onConnect(event) {
        // The chart was just created
        this.chart = event.detail.chart;
            
        // For instance you can format Y axis
        this.chart.data.datasets[0].backgroundColor = (ctx) => this.getGradient(ctx);
        this.chart.data.datasets[0].borderColor = '#25C8E5';
        this.chart.options.plugins.legend.labels.color = 'val(--gray-900)';
        
        this. chart.options.animations = {
            radius: {
                duration: totalAnimationDuration,
                easing: 'linear',
                loop: (context) => context.active
            },
        }

        this.chart.update();
    }
}