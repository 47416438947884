import { wrapGrid } from 'animate-css-grid';
import { Controller } from 'stimulus';

const refreshInterval = 10000;
const blinkDuration = 1400;

export default class extends Controller {
    lastMouseMoveTimeout = null;
    isDragging = false; // Permet de savoir si le drag provient bien de cette instance du controller
    startDragTime = null;
    static isOneControllerDragging = false;

    entityId = null;

    tileDragStart(e) {
        if(this.isOneControllerDragging) return;
        if(!this.element.isEqualNode(jQuery(e.target).parent().get(0))) return;
        this.isDragging = true;
        this.isOneControllerDragging = true;

        let line = jQuery(e.target).closest('.line');
        this.entityId = line.data('id');
    }

    laneDragEnter(e) {
        e.preventDefault();

        if(!this.isDragging) return;

        let targetLine = jQuery(e.target).closest('.line');
        while(targetLine.length == 1 && !this.element.isEqualNode(targetLine.closest('.line').parent().get(0)))
            targetLine = jQuery(targetLine).parent().closest('.line');

        if(targetLine.length != 1) return;

        if(targetLine.data('id') !=  this.entityId) {
            if(!this.startDragTime) this.startDragTime = new Date();
            else if(new Date().getTime() - this.startDragTime.getTime() >= 100) {
                let line = jQuery(this.element).children(`.line[data-id="${this.entityId}"]`);
                if(targetLine.index() > line.index()) targetLine.after(line);
                else targetLine.before(line)
                this.startDragTime = null;
            }
        }
    }

    laneDragEnd(e) {
        e.preventDefault();
        if(!e.dataTransfer) e.dataTransfer = e.originalEvent.dataTransfer;

        if(!this.isDragging) return;
        this.isDragging = false;
        this.isOneControllerDragging = false;

        if(e.dataTransfer.mozUserCancelled)
            jQuery(this.element).children('.line')
                .sort((a, b) => jQuery(a).find('input').val() - jQuery(b).find('input').val())
                .each((i, input) => jQuery(this.element).append(input));
        else
            jQuery(this.element).children('.line').find('input')
                .filter((i, input) => this.element.isEqualNode(jQuery(input).closest('.line').parent().get(0)))
                .each((i, input) => jQuery(input).val(i + 1));
    }

    connect() {
        // Drag and drop tiles
        jQuery(this.element).children('.line').on('dragstart', (e) => this.tileDragStart(e));
        jQuery(this.element).children('.line').on('dragenter', (e) => this.laneDragEnter(e));
        jQuery(this.element).on('dragend', (e) => this.laneDragEnd(e));
    }
}