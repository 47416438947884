import { Controller } from 'stimulus';

export default class extends Controller {

    connect() {
        var select2Group = jQuery('#SupportTicket_assignatedGroup');
        select2Group.append('<option value="-1">Autre</option>')
        jQuery('#SupportTicket_assignatedGroup').parent().on('focusout', (e) => {
            setTimeout(() => {
                if(jQuery('#SupportTicket_assignatedGroup').val() == -1) {
                    jQuery('#edit-SupportTicket-form > .field-form_panel:nth-child(3)').show(1000);
                } else {
                    jQuery('#edit-SupportTicket-form > .field-form_panel:nth-child(3)').hide(1000);
                }
            }, 100)
        });

        if(!jQuery('#SupportTicket_assignatedGroup').val()) {
            jQuery('#SupportTicket_assignatedGroup').val(-1);
        }

        if(jQuery('#SupportTicket_assignatedGroup').val() == -1) {
            jQuery('#edit-SupportTicket-form > .field-form_panel:nth-child(3)').show();
        } else {
            if(jQuery('#SupportTicket_assignatedGroup').val() > 0) {
                jQuery('SupportTicket_assignatedGroup_usersArray').attr('selected', null)
            }
            jQuery('#edit-SupportTicket-form > .field-form_panel:nth-child(3)').hide();
        }
    }
}