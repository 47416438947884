import { Controller } from 'stimulus';
import Select2Cascade from '../js/select2-cascade';

export default class extends Controller {
    static values = {
        parentSelectName: String,
        childFieldName: String,
        urlGetChild: String
    }

    connect() {
        let formName = jQuery('.ea-new-form, .ea-edit-form').attr('name');
        new Select2Cascade().linkSelects(jQuery(`[name="${formName}[${this.parentSelectNameValue}]"]`), jQuery(`[name="${formName}[${this.childFieldNameValue}]"]`), this.urlGetChildValue);
    }
}