import moment from 'moment';
import { Controller } from 'stimulus';

export default class extends Controller {
    static values = {
        timestamp: Number
    }

    connect() {
        jQuery(this.element).text(moment().subtract(new Date().getTime() / 1000 - this.timestampValue, 'seconds').locale('fr').fromNow());
    }
}
