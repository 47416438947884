import { Controller } from 'stimulus';
import PagesModal from '../js/pages-modal';
import MailPreview from '../js/mail-previews';
import FormUtils from '../js/form-utils';
import TrixAttachmentHandler from '../js/trix-attachment-handler';
import CustomMailPreview from '../js/custom-mail-preview';

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
  
        formData.append(parentKey, value);
    }
}
  
function objectToFormData(data) {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
}

export default class extends Controller {

    static values = {
        urlCompleteSubStep: String,
        urlUndoSubStep: String,
        urlEditNotes: String,
        urlEditComment: String,
        urlSetDeadline: String,
        urlUploadSubStepAttachment: String,
    };

    writeNotesUrl;
    writeCommentUrl;
    stepId = 0;
    substepProgressId = 0;
	pagesModal;
	pagesModalUndo;
	pagesModalAlertSalesman;
    trixAttachmentHandlerPages;
    trixAttachmentHandlerPagesUndo;
    trixAttachmentHandlerPagesAlertSalesman;

    pushPreviewsToData(data, previewsJquery, defaultAttachmentsElems, attachments) {
        previewsJquery.each((_, input) => {
            let name = jQuery(input).prop('name');
            const path = name.split('_');
            if(!data.previews[path[0]]) data.previews[path[0]] = {};
            let dataEntry = data.previews[path[0]];
            if(!dataEntry[path[1]]) dataEntry[path[1]] = {};
            dataEntry = dataEntry[path[1]];
            dataEntry['content'] = jQuery(input).val();

            if(defaultAttachmentsElems)
                dataEntry['defaultAttachmentsIds'] = defaultAttachmentsElems.filter((elem) => jQuery(elem).closest('.initial-attachments').data('key') == name).map((elem) => elem.id);

            Object.keys(attachments ?? {}).forEach((fileHash, i) => {
                if(!dataEntry.files) dataEntry.files = [];
                dataEntry.files.push(attachments[fileHash]);
            }, self);
        });
    }

    completeSubStep() {
        var data = { previews: {}, service: {} };
        data['substep'] = this.substepProgressId;
        data.notes = jQuery('#modalCompleteSubStepLabelNotes').val() ?? '';
        this.pushPreviewsToData(
            data,
            jQuery('#' + this.pagesModal.getHTMLId() + ' .mail-preview-page input[type="hidden"]:not([disabled="disabled"])'),
            jQuery('#' + this.pagesModal.getHTMLId() + ' .mail-preview-page .initial-attachments > div').toArray(),
            this.trixAttachmentHandlerPages.getAttachments()
        );

        if(jQuery('.pre-step-submit-form [name="Service[installer]"]').length) data.service.installer = jQuery('.pre-step-submit-form [name="Service[installer]"]').val();
        if(jQuery('.pre-step-submit-form [name="Service[installationDate]"]').length) data.service.installationDate = jQuery('.pre-step-submit-form [name="Service[installationDate]"]').val();

        let formData = objectToFormData(data);

        jQuery.ajax({
            type: "POST",
            url: this.urlCompleteSubStepValue,
            data: formData,
            contentType: false,
            processData: false
        }).always(() => { document.location.reload() });
    }

    undoSubstep() {
        var data = { previews: {}, service: {} };
        data['substep'] = this.substepProgressId;
        this.pushPreviewsToData(
            data,
            jQuery('#' + this.pagesModalUndo.getHTMLId() + ' .mail-preview-page input[type="hidden"]:not([disabled="disabled"])'),
            jQuery('#' + this.pagesModalUndo.getHTMLId() + ' .mail-preview-page .initial-attachments > div').toArray(),
            this.trixAttachmentHandlerPagesUndo.getAttachments()
        );

        let formData = objectToFormData(data);

        jQuery.ajax({
            type: "POST",
            url: this.urlUndoSubStepValue,
            data: formData,
            contentType: false,
            processData: false
        }).always(() => { document.location.reload() });
    }

    editNotesSubStep() {
        var data = {};
        data['substep'] = this.substepProgressId;
        data.notes = jQuery('#modalEditNotesNotes').val();

        jQuery.post(this.urlEditNotesValue, data).then(() => {
            document.location.reload();
        }).catch(e => {
            jQuery('#modalEditNotes').modal('hide');
        });
    }

    setDeadlineStep() {
        var data = {};
        data['step'] = this.stepId;
        data.deadline = jQuery('#modalPickDeadlineDate').val();

        jQuery.post(this.urlSetDeadlineValue, data).then(() => {
            document.location.reload();
        }).catch(e => {
            jQuery('#modalPickDeadline').modal('hide');
        });
    }

    showModalComplete(e) {
        var checkbox = jQuery(e.target);
        this.pagesModal.onConfirm(() => this.completeSubStep());
        MailPreview.getSubstepProgressCompletionMailPreviews(this.substepProgressId).then(async (pages) => {
            this.pagesModal
                .clearPages()
                .addPages(...pages)
                .addPage(`
                    <div class="modal-submit-page">
                        <p>Voulez-vous vraiment valider la tâche de cette étape ?<br/>Cette action ne peut être annulée que par un administreur ou un responsable déploiement<p>
                        <div class="form-group">
                            <textarea class="form-control" id="modalCompleteSubStepLabelNotes" rows="3" autocomplete="off" placeholder="Notes sur la tâche"></textarea>
                        </div>
                    </div>
                `)
                .onConfirm(() => this.completeSubStep())
                .show();
            jQuery('#modalCompleteSubStepLabelNotes').val(jQuery(e.target).parentsUntil('.substeps').last().find('.substep-notes').text().trim());
            this.trixAttachmentHandlerPages.addEventListener();
            jQuery('#modalCompleteSubStepId').text(checkbox.data('name'));
            jQuery('[name="Service[installer]"]').select2({ theme: "bootstrap" });
        });
    }

    showModalPreValidationForm(preValidationForm, e) {
        this.pagesModal
            .clearPages()
            .addPages(preValidationForm)
            .onConfirm(() => {
                FormUtils.ajaxSubmitForm('.pre-step-submit-form form').done((res, _, xhr) => xhr.status == 201 ? this.showModalComplete(e) : this.showModalPreValidationForm(res));
            })
            .show();
        this.trixAttachmentHandlerPages.addEventListener();
    }

    toggleSubstepCompletion(e) {
        var checkbox = jQuery(e.target);
        var currentStepOrder = jQuery(e.target).attr('data-order');
        var previousStepsCheckboxes = jQuery('.step').filter((i, s) => jQuery(s).attr('data-order') < currentStepOrder).parent().find('input[type="checkbox"]');

        if(previousStepsCheckboxes.filter((i, c) => !jQuery(c).is(":checked")).length == 0) {
            this.substepProgressId = checkbox.val();
            if(checkbox.is(":checked")) {
                jQuery.get('/api/substep/' + this.substepProgressId +'/form/pre-validation')
                    .done(form => form ? this.showModalPreValidationForm(form, e) : this.showModalComplete(e));
            } else {
                if(confirm('Voulez vous vraiment remettre cette tâche en inachevée ?')) {
                    this.pagesModalUndo.clearPages();
                    MailPreview.getSubstepProgressUncompletionMailPreviews(this.substepProgressId).then(async (pages) => {
                        this.pagesModalUndo.addPages(...pages).show();
                        this.trixAttachmentHandlerPagesUndo.addEventListener();
                        jQuery('#modalUndoSubStepId').text(checkbox.data('name'));
                    });
                }
            }
        } else if(checkbox.is(":checked")) {
            alert('Les tâches de cette étape ne peuvent être validées car les étapes précédentes ne sont pas terminées')
        }
        
        e.preventDefault();
        return false;
    }

    showModalEditNotes(e) {
        var checkbox = jQuery(e.target).parent().children().first();
        this.substepProgressId = checkbox.val();
        jQuery('#modalEditNotesId').text(checkbox.attr('data-name'));
        jQuery('#modalEditNotesNotes').val(jQuery.trim(jQuery(e.target).parentsUntil('.substeps').last().find('.substep-notes').text()));
        jQuery.noConflict(false); 
        jQuery('#modalEditNotes').modal('show');
    }

    showModalAddComment(e) {
        var checkbox = jQuery(e.target).parent().children().first();
        this.substepProgressId = checkbox.val();
        jQuery.noConflict(false); 
        jQuery('#modalAddComment').modal('show');
    }

    showModalEditComment(e) {
        var checkbox = jQuery(e.target).parentsUntil('.substeps').last().find('.substep-label').children().first();
        this.substepProgressId = checkbox.val();
        jQuery.noConflict(false);
        jQuery('#modalEditComment').data('commentId',jQuery(e.target).parent().data('id'));
        jQuery('#modalEditCommentNotes').val(jQuery(e.target).parent().find('.substep-comment-content').text());
        jQuery('#modalEditComment').modal('show');
    }

    showUploadFile(e) {
        jQuery(e.target).parent().find('input[type="file"]').trigger('click');
    }

    deleteFile(e) {
        if(!e.isDefaultPrevented())
            jQuery.ajax({ url: '/api/file-attachment/' + jQuery(e.target).parent().data('id'), method: 'DELETE' }).then(() => document.location.reload());
    }

    uploadSubstepAttachment(e) {
        if(e.target.files) {
            document.body.style.cursor='wait';
            let formData = new FormData();
            formData.append("substepProgressId", $(e.target).data('id'));
            formData.append("file", e.target.files[0]);
            fetch(this.urlUploadSubStepAttachmentValue, { method: "POST", body: formData }).then(() => document.location.reload())
        }
    }

    showModalSetManualDeadline(e) {
        var button = jQuery(e.target);
        var currentDate = jQuery.trim(button.parent().find('.step-status-time').text()).split('/').reverse().join('-');
        this.stepId = button.parent().parent().attr('data-id');
        jQuery('#modalPickDeadlineStepId').text(button.parent().parent().attr('data-name'));
        jQuery('#modalPickDeadlineDate').val(currentDate);
        jQuery.noConflict(false);
        jQuery('#modalPickDeadline').modal('show');
    }

    writeNotes(data) {
        data.content = jQuery('#modalWriteNotesContent').val();

        jQuery.post(this.writeNotesUrl, data).then(() => {
            document.location.reload();
        }).catch(e => {
            jQuery('#modalWriteNotes').modal('hide');
        });
    }

    writeComment(data) {
        var data = {};
        data['substep'] = this.substepProgressId;
        data['comment'] = jQuery('#modalEditComment').data('commentId')
        data.content = jQuery(data.comment != null ? '#modalEditCommentNotes' : '#modalAddCommentNotes').val();

        jQuery.post(this.urlEditCommentValue, data).then(() => {
            document.location.reload();
        }).catch(e => {
            jQuery('#modalAddComment').modal('hide');
            jQuery('#modalEditComment').modal('hide');
        });
    }

    writeSpecifitiesAndConstraints() {
        jQuery.ajax('/api/service/' + jQuery('#titleService').data('service-id'), {
            method: 'PUT',
            data: {
                specificitiesAndConstraints: jQuery('#modalSpecificitiesAndConstraintsContent').val()
            }
        }).then(() => {
            document.location.reload();
        }).catch(e => {
            jQuery('#modalSpecificitiesAndConstraints').modal('hide');
        });
    }

    showModalWriteNotes(e) {
        e.preventDefault();
        e.stopPropagation();

        this.writeNotesUrl = jQuery(e.target).attr('href');

        jQuery('#modalWriteNotes').modal('show');
    }

    showModalEditSpecificitiesAndConstraints(e) {
        e.preventDefault();
        e.stopPropagation();

        jQuery('#modalSpecificitiesAndConstraints').modal('show');
    }

    showAlertSalesmanPreview(e) {
        var checkbox = jQuery(e.target).parentsUntil('.substep').last().find('[name="substep"]');
        this.substepProgressId = checkbox.val();
        this.pagesModalUndo.clearPages();
        MailPreview.getSubstepProgressAlertSalesmanMailPreviews(this.substepProgressId).then(async (pages) => {
            this.pagesModalAlertSalesman.addPages(...pages).show();
            this.trixAttachmentHandlerPagesAlertSalesman.addEventListener();
            jQuery('#modalAlertSalesmanSubStepId').text(checkbox.data('name'));
        });
    }

    sendAlertSalesman() {
        var data = { previews: {}, service: {} };
        data['substep'] = this.substepProgressId;
        this.pushPreviewsToData(
            data,
            jQuery('#' + this.pagesModalAlertSalesman.getHTMLId() + ' .mail-preview-page input[type="hidden"]:not([disabled="disabled"])'),
            jQuery('#' + this.pagesModalAlertSalesman.getHTMLId() + ' .mail-preview-page .initial-attachments > div').toArray(),
            this.trixAttachmentHandlerPages.getAttachments()
        );

        let formData = objectToFormData(data);

        jQuery.ajax({
            type: "POST",
            url: '/api/substep-progress/' + this.substepProgressId + '/alert-salesman',
            data: formData,
            contentType: false,
            processData: false
        }).always(() => { document.location.reload() });
    }

    connect() {
        jQuery('.substeps input[type="checkbox"]').on("click", (e) => this.toggleSubstepCompletion(e));
        jQuery('.substeps .substep-label .fa-edit').on("click", (e) => this.showModalEditNotes(e));
        jQuery('.substeps .substep-label .fa-paperclip').on("click", (e) => this.showUploadFile(e));
        jQuery('.substeps .substep-label .fa-comment-medical').on("click", (e) => this.showModalAddComment(e));
        jQuery('.substeps .substep-comment .fa-pen').on("click", (e) => this.showModalEditComment(e));
        jQuery('.service-specificities .fa-edit').on("click", (e) => this.showModalEditSpecificitiesAndConstraints(e));
        setTimeout(() => jQuery('.substep-file .fa-times').on("click", (e) => this.deleteFile(e)), 200);
        jQuery('.substeps input[type="file"]').on("change", (e) => this.uploadSubstepAttachment(e));
        jQuery('.step-status .fa-edit').on("click", (e) => this.showModalSetManualDeadline(e));
        jQuery('.btn-salesman-alert').on('click', (e) => this.showAlertSalesmanPreview(e));

        this.pagesModal = new PagesModal('Validation de l\'acomplissement de la tâche <div id="modalCompleteSubStepId"></div>');

        this.pagesModalUndo = new PagesModal('Remise en inachevée de la tâche <div id="modalUndoSubStepId"></div>');
        this.pagesModalUndo.onConfirm(() => this.undoSubstep());

        this.pagesModalAlertSalesman = new PagesModal('Alerte - Retour Commercial - Sous-étape <div id="modalAlertSalesmanSubStepId"></div>');
        this.pagesModalAlertSalesman.onConfirm(() => this.sendAlertSalesman());
        
        this.trixAttachmentHandlerPages = new TrixAttachmentHandler('#' + this.pagesModal.getHTMLId());
        this.trixAttachmentHandlerPagesUndo = new TrixAttachmentHandler('#' + this.pagesModalUndo.getHTMLId());
        this.trixAttachmentHandlerPagesAlertSalesman = new TrixAttachmentHandler('#' + this.pagesModalAlertSalesman.getHTMLId());

        (new CustomMailPreview('Envoi d\'un e-mail', (_) => (new URLSearchParams(window.location.search)).get('serviceId'))).enable('.action-sendMail');

        jQuery('#confirmModalAddComment').on('click', (e) => this.writeComment({}));
        jQuery('#confirmModalEditComment').on('click', (e) => this.writeComment({}));
        jQuery('#confirmModalEditNotes').on('click', () => this.editNotesSubStep());
        jQuery('#confirmModalPickDeadline').on('click', () => this.setDeadlineStep());

        jQuery('.action-phoneCall').on('click', (e) => this.showModalWriteNotes(e));
        jQuery('.action-sendSMS').on('click', (e) => this.showModalWriteNotes(e));
        jQuery('.action-rdvSite').on('click', (e) => this.showModalWriteNotes(e));

        jQuery('#confirmModalWriteNotes').on('click', (e) => this.writeNotes({}));
        jQuery('#confirmModalSpecificitiesAndConstraints').on('click', (e) => this.writeSpecifitiesAndConstraints());
    }
}