import { Controller } from 'stimulus';
import TrixAttachmentHandler from '../js/trix-attachment-handler';

async function getBase64ForInput(file, jQueryElem) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            jQueryElem.val(reader.result.split(',')[1]);
            resolve(true);
        };
        reader.onerror = () => reject();
    });
}

export default class extends Controller {
    trixAttachmentHandler;
    areAttachmentsLoaded = false;

    connect() {
        let i = 1;
        jQuery('.field-collection-add-button').hide();

        jQuery('#MailTemplate_body').parentsUntil('.form-group').find('.placeholder-picker-widget').wrapAll('<div class="picker-widget-wrapper"></div>');
        jQuery('#MailTemplate_body').parentsUntil('.form-group').find('.placeholder-picker-widget').wrap('<label style="margin:0;"></label>');
        jQuery('.picker-widget-wrapper .placeholder-picker-widget').each(function() {
            $(this).parent().before('<input type="radio" id="ppw_' + i + '" name="ppw"></input>')
            $(this).parent().after('<div></div>')
            $(this).find('label').attr('for', 'ppw_' + i);
            i++;
        });
        jQuery('.placeholder-picker-widget .placeholder-picker').attr('disabled', true);
        jQuery('.picker-widget-wrapper input[type=radio][name="ppw"]').on('change', (e) => {
            jQuery('.placeholder-picker-widget .placeholder-picker').attr('disabled', true);
            $(e.target).next().find('.placeholder-picker-widget .placeholder-picker').attr('disabled', false);
        });

        jQuery('.ea-text-editor-wrapper').prepend('<div class="editor-attachments"></div>');
        this.trixAttachmentHandler = new TrixAttachmentHandler('.ea-text-editor-wrapper');
        this.trixAttachmentHandler.addEventListener();

        

        jQuery('#new-MailTemplate-form, #edit-MailTemplate-form').on('submit', async (e) => {
            let attachments = this.trixAttachmentHandler.getAttachments();

            if(Object.values(attachments).length && !this.areAttachmentsLoaded) {
                e.preventDefault();
                e.stopPropagation();
                let fileLoadingPromises = [];
                Object.values(attachments).forEach((attachment) => {
                    if(attachment.size < 10000000) {
                        jQuery('.field-collection-add-button').trigger('click');
                        let iAttachment = jQuery('#new-MailTemplate-form, #edit-MailTemplate-form')
                            .serializeArray()
                            .filter((input) => input.name.startsWith('MailTemplate[attachments]'))
                            .map((input) => parseInt(input.name.split(/\]\[/)[1]))
                            .reduce(((a, b) => Math.max(a, b)));
                        jQuery('[name="MailTemplate[attachments][' + iAttachment + '][name]"]').val(attachment.name);
                        jQuery('[name="MailTemplate[attachments][' + iAttachment + '][mimeType]"]').val(attachment.type);
                        fileLoadingPromises.push(getBase64ForInput(attachment, jQuery('[name="MailTemplate[attachments][' + iAttachment + '][content][base64Content]"]')));
                    }
                });
                await Promise.allSettled(fileLoadingPromises);
                this.areAttachmentsLoaded = true;
                if(e.originalEvent && e.originalEvent.submitter) jQuery(e.originalEvent.submitter).trigger('click');
            }
        });
    }
}
