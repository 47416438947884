import { Controller } from 'stimulus';

function formatNumberToCurrency(number, currency = '€') {
    let nb = Math.round(parseFloat(number) * 100) / 100;
    return (nb == parseInt(nb + '') ? parseInt(nb + '') : nb.toLocaleString('fr-FR', { minimumIntegerDigits: 1, minimumFractionDigits: 2 })) + ' ' + currency;
}

export default class extends Controller {
    static values = {
        familyTree : Array,
        productsByFamily : Object,
        quotationLineTwigTemplate: String
    }

    productsById;
    productsByFamily;
    quotationSiteLines;
    quotationLineTwigTemplate;
    totalsSite;
    salesModel = {
        billingType: 0,
        monthNumber: 0
    }

    getTreeNodeById(tree, id) {
        return Object.values(tree).map(node => node.id == id ? node : (node.children ? this.getTreeNodeById(node.children, id) : null)).filter(node => node).shift();
    }

    listenForPropertyUpdateToRefresh(dataName) {
        jQuery(this.element).find('[data-name="' + dataName + '"]').on('change', (e) => {
            
            let quotationLine = jQuery(e.target).parentsUntil('.site-lines-wrapper').last();
            let value = jQuery(e.target).val();
            const lineIndex = quotationLine.data('index') - 1;
            let intVal = parseInt(value);
            let floatVal = parseFloat((value + '').replace(',', '.'));
            this.quotationSiteLines[lineIndex][dataName] = intVal && intVal == floatVal ? intVal : (floatVal ? floatVal : value);
            if(dataName == 'product'){
                this.quotationSiteLines[lineIndex].productFlatPrice = null;
                this.quotationSiteLines[lineIndex].productMonthlyPrice = null;
            }
            this.renderQuotationService();
        });
    }

    renderQuotationServiceLines() {
        let htmlLines = [];
        this.quotationSiteLines.forEach((line, i) => {
            let salesPrice = null;
            let productChoices = (line.selectedFamiliesId && line.selectedFamiliesId.length > 0 ? line.selectedFamiliesId.map(id => this.productsByFamily[id]).flat() : Object.values(this.productsById)).filter(p => p != null);

            if(productChoices.length > 0 && !(line.product && productChoices.filter(p => p.id == line.product).length)) line.product = productChoices[0].id;

            let product = line.product ? this.productsById[line.product] : null;
            if(product) {
                product.flatPriceFormatted = formatNumberToCurrency(product.flatPrice);
                product.monthlyPriceFormatted = formatNumberToCurrency(product.monthlyPrice);
                if(line.quantity) {
                    salesPrice = {
                        flat: this.productsById[line.product].flatPrice * line.quantity * (1 + (line.margin?? 0)  / 100 ),
                        monthly: this.productsById[line.product].monthlyPrice * line.quantity * (1 + (line.margin ?? 0) / 100 )
                    };
                    this.totalsSite.ha_flat_rate += product.flatPrice * line.quantity;
                    this.totalsSite.ha_monthly += product.monthlyPrice * line.quantity;
                    this.totalsSite.quantity += parseInt(line.quantity);
                    this.totalsSite.sales_flat_rate += salesPrice.flat;
                    this.totalsSite.sales_monthly += salesPrice.monthly;
                    salesPrice.flat = formatNumberToCurrency(salesPrice.flat);
                    salesPrice.monthly = formatNumberToCurrency(salesPrice.monthly);
                }
            }

            htmlLines.push(this.quotationLineTwigTemplate.render({ index: i + 1, line, product, productChoices, salesPrice }));
        });

        let haTotal = this.totalsSite.ha_flat_rate + this.totalsSite.ha_monthly * this.salesModel.monthNumber;
        this.totalsSite.ca = this.totalsSite.sales_flat_rate + this.totalsSite.sales_monthly * this.salesModel.monthNumber;
        let margin = Math.round(10000 * (this.totalsSite.ca > 0 ? this.totalsSite.ca / haTotal : 0)) / 100 - 100;
        this.totalsSite.iko_margin = haTotal * margin / 100;
        this.totalsSite.margin = margin.toLocaleString('fr-FR')
        return htmlLines;
    }

    renderQuotationService() {
        this.totalsSite =  { ha_flat_rate: 0, ha_monthly: 0, quantity: 0, margin: 0, sales_flat_rate: 0, sales_monthly: 0, iko_margin: 0, ca: 0 };
        jQuery(this.element).find('.site-lines-wrapper').html(this.renderQuotationServiceLines());
        this.element.dispatchEvent(new CustomEvent('totals-update', { detail: { lines: jQuery.extend({}, this.quotationSiteLines), totals: jQuery.extend({}, this.totalsSite) } }));

        let quotationTotalLine = jQuery(this.element).parent().find('.quotation-total-line');
        Object.keys(this.totalsSite).forEach((totalKey) => quotationTotalLine.find('[data-name="' + totalKey + '"] input').val(totalKey == 'margin' ? this.totalsSite[totalKey] + ' %' : (totalKey.includes('quantity') ? this.totalsSite[totalKey] : formatNumberToCurrency(this.totalsSite[totalKey]))));

        jQuery(this.element).find('[data-name="selectedFamily"]').on('click', (e) => {
            jQuery(e.target).toggleClass('show');
        });
        jQuery(this.element).find('.delete-line').on('click', (e) => {
            const lineIndex = jQuery(e.target).parentsUntil('.site-lines-wrapper').last().data('index') - 1;
            this.quotationSiteLines.splice(lineIndex, 1);
            this.renderQuotationService();
        });

        jQuery('[name="margin"]').on('change', () => {});
        jQuery(this.element).find('[data-name="family"]').jstree({
            "core" : {
                "data": this.familyTreeValue,
                "themes" : {
                    'variant': 'large',
                    'icons' : false
                }
            },
            "checkbox" : {
                "keep_selected_style" : false
            },
            "plugins" : [ "wholerow", "checkbox" ]
        });

        setTimeout(() => {
            jQuery('.jstree').each((i, elem) => {
                const lineIndex = jQuery(elem).parentsUntil('.site-lines-wrapper').last().data('index') - 1;
                let line = this.quotationSiteLines[lineIndex];
                if(line && line.selectedFamiliesId) jQuery(elem).jstree('check_node', [line.selectedFamiliesId]);
            });
    
            jQuery('.jstree').on('changed.jstree', (e, data) => {
                if(data.event && data.event.currentTarget.closest('#' + this.element.id)) {
                    let quotationLine = jQuery(data.event.currentTarget).parentsUntil('.site-lines-wrapper').last();
                    const lineIndex = quotationLine.data('index') - 1;
                    this.quotationSiteLines[lineIndex].selectedFamiliesId = data.selected.map(id => parseInt(id));
                    this.quotationSiteLines[lineIndex].selectedFamily = data.selected.map(id => data.instance.get_node(id).text).join(', ');
    
                    this.quotationSiteLines[lineIndex].selectedFamilyWarning = data.selected
                        .map(id => this.getTreeNodeById(this.familyTreeValue, id))
                        .filter(node => node.warning)
                        .map(node => '<b>' + node.name + ':</b> ' + node.warning)
                        .join('<br/>');
                    quotationLine.find('[data-name="selectedFamily"]').text(this.quotationSiteLines[lineIndex].selectedFamily);
                }
            });
        }, 500)
       

        jQuery(this.element).parent().on('click', (e) => {
            if(!jQuery(e.target).closest('.jstree').length && !jQuery(e.target).closest('[data-name="selectedFamily"]').length && jQuery('.show[data-name="selectedFamily"]').length) {
                jQuery('.show[data-name="selectedFamily"]').removeClass('show');
                this.renderQuotationService();
            }
        });

        jQuery(this.element).find('[data-name="product"]').select2({
            theme: 'bootstrap',
        });

        this.listenForPropertyUpdateToRefresh('product');
        this.listenForPropertyUpdateToRefresh('quantity');
        this.listenForPropertyUpdateToRefresh('margin');
        this.listenForPropertyUpdateToRefresh('note');

        jQuery('[data-name="product"]').on('select2:open', function (e) {
            const evt = "scroll.select2";
            jQuery(e.target).parents().off(evt);
            jQuery(window).off(evt);
        });

        jQuery('.family-tooltip i').tooltip({ placement: 'right', html: true })
    }

    connect() {
        this.quotationLineTwigTemplate = Twig.twig({
            data: this.quotationLineTwigTemplateValue
        });
        
        this.quotationSiteLines = [];
        const siteIndex = jQuery(this.element).data('index');
        jQuery('#edit-Quotation-form').serializeArray().forEach((attr) => {
            const regexMatches = attr.name.match('^Quotation\\[sites\\]\\[' + siteIndex + '\\]\\[siteLines\\]\\[([0-9]+)\\]\\[([a-zMFP]*)\\]');
            if(regexMatches && regexMatches.length == 3) {
                if(!this.quotationSiteLines[regexMatches[1]]) this.quotationSiteLines[regexMatches[1]] = {};
                if(regexMatches[2] == 'product') this.quotationSiteLines[regexMatches[1]]['product'] = parseInt(jQuery('#edit-Quotation-form').find('[name="Quotation[sites][' + siteIndex + '][siteLines][' + regexMatches[1] + '][product]"] option[selected="selected"]').val());
                else this.quotationSiteLines[regexMatches[1]][regexMatches[2]] = parseInt(attr.value) && parseInt(attr.value) == parseFloat(attr.value.replace(',', '.')) ? parseInt(attr.value) : (parseFloat(attr.value) ? parseFloat(attr.value.replace(',', '.')) : attr.value);
            }
        });
        
        this.productsByFamily = Object.fromEntries(Object.keys(this.productsByFamilyValue).map(
            familyId => [familyId, this.productsByFamilyValue[familyId].map((pList) => Object.values(pList)).flat()]
        ));
        this.productsById = {};
        Object.values(this.productsByFamily).forEach(pList => pList.forEach(p => { if(p.id) this.productsById[p.id] = p; }));

        jQuery(this.element).find('.add-product-line').on('click', () => {
            this.quotationSiteLines.push({ quantity: 1, margin: 35 })
            this.renderQuotationService();
        });

        jQuery('.global-quotation-content').on('sales-model-update', (e) => {
            this.salesModel = e.detail;
            this.renderQuotationService();
            this.element.dispatchEvent(new CustomEvent('totals-update', { detail: { lines: jQuery.extend({}, this.quotationSiteLines), totals: jQuery.extend({}, this.totalsSite) } }));
        });

        this.renderQuotationService();
    }
}