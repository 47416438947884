import 'moment';
import { Controller } from 'stimulus';

export default class extends Controller {

    static values = {
        mapFilters : Object,
        mapFiltersValues : Object,
        multiSelectionFilters: Array,
        incompatibleFilters: Array
    }
    callback = (filter, id) => {};
    listFilters = [];
    listValues = '';
    mapFilters = new Map();
    strFilterSelected = '';
    timeUnitAmount = 1;
    key = '';
    isMultiSelection = false;

    setValueList() {
        this.strFilterSelected = jQuery('#selectColumn :selected').val()
        if(!this.strFilterSelected) { jQuery('#selectValue').hide(); jQuery('#selectValue + .select2').hide(); return; }
        this.isMultiSelection = this.multiSelectionFiltersValue.includes(this.strFilterSelected);
        this.listValues = this.mapFilters.get(this.strFilterSelected);
        jQuery('#selectValue').css('display', 'inline-block');
        jQuery('#selectValue + .select2').css('display', 'inline-block');
        jQuery('#selectValue').empty();
        jQuery('#selectValue').append('<option value="" selected></option>');
        this.listValues.forEach(option => jQuery('#selectValue').append(`<option value="${option.id}">${option.display}</option>`));
    }

    saveFilter(event) {
        this.key = jQuery('#selectValue :selected').val()
        if(this.key != '') {
            let strFilter = this.strFilterSelected;
            if(this.isMultiSelection && this.listFilters.find(el => el.id == strFilter) != null && this.listFilters.find(el => el.id == strFilter).value == this.key) {
                return false;
            } else if(this.listFilters.find(el => el.id == strFilter) != null && !this.isMultiSelection) {
                this.listFilters.find(el => el.id == strFilter).value = this.key;
                this.listFilters.find(el => el.id == strFilter).display = this.getDisplayFilter();
            } else {
                this.listFilters.push({
                    id : strFilter,
                    value: this.key,
                    display: this.getDisplayFilter()
                });
            }
            if(this.incompatibleFiltersValue.includes(strFilter))
                this.incompatibleFiltersValue.forEach(filter => filter != strFilter ? this.listFilters = this.listFilters.filter(f => f.id != filter) : '');
            
            this.refreshList();
            this.resetComboBoxes();

            let values = jQuery(`#listSelectedValues [id^="${strFilter}#"]`).map((_, elem) => jQuery(elem).attr('id').split('#')[1]).toArray();
            
            jQuery(document).trigger('set-filter', [{ filter: strFilter, values: this.multiSelectionFiltersValue.includes(strFilter) ? values : values[0] }]);
            if(this.isMultiSelection) this.setValueList();
            this.refreshList();
        }
    }

    refreshList() {
        this.listToMap(this.listFilters).forEach(this.callback);
        jQuery('#listSelectedValues').empty();
        this.listFilters.forEach(filter => jQuery('#listSelectedValues').append(`
            <div id="${filter.id}#${filter.value}" class="waves-effect waves-light btn darkGrey">
                <span>
                    <i class="fas fa-times"></i>
                    ${this.mapFiltersValue[filter.id]} : <span id="${filter.value}" class="fieldValue" disabled>${filter.display}</span>
                </span>
            </div>
        `))
        jQuery('#listSelectedValues > div').off();
        jQuery('#listSelectedValues > div').on('click', (event) => this.loadFilterFields(event));
        jQuery('#listSelectedValues .fa-times').off();
        jQuery('#listSelectedValues .fa-times').on('click', (event) => this.removeFilter(event.target.parentNode.parentNode.id) && event.stopPropagation());
    }

    listToMap(list) {
        const map = new Map();
        list.forEach(el => {
            if(!map.has(el.id)) {
                map.set(el.id, list.filter(el2 => el.id == el2.id));
            }
        });
        return map;
    }

    removeFilter(filterId) {
        this.listFilters = this.listFilters.filter(f => f.id + '#' + f.value != filterId);
        this.refreshList();

        let filter = filterId.split('#')[0];
        let value = filterId.split('#')[1];
        
        jQuery(document).trigger('unset-filter', [{ filter, value, isMultiSelection: this.multiSelectionFiltersValue.includes(filter) }]);
    }

    loadFilterFields(event) {
        let target = event.target;
        while(target.tagName.toLowerCase() != 'div') {
            target = target.parentNode;
        }
        jQuery('#selectColumn').val(target.id.substring(0, target.id.indexOf('#')));

        this.setValueList();
    }

    retrieveAllValuesForRequest(filters, indexFilter) {
        let str = "";
        filters.forEach((filter, i) => str += '&Filters[' + indexFilter + '].' + 'Values[' + i + ']=' + filter.value);
        return str;
    }

    getDisplayFilter() {
        return this.listValues.find(el => el.id == this.key).display
    }

    resetComboBoxes() {
        this.strFilterSelected = '';
        jQuery('#selectColumn option').attr('selected', null)
        jQuery('#selectValue').toggle()
        jQuery('#selectValue + .select2').toggle()
    }

    isTimeUnitAmountValid() {
        return document.getElementById('timeUnitAmount') != null ? document.getElementById('timeUnitAmount').checkValidity() : true;
    }

    connect() {
        jQuery('#selectValue, #selectColumn').select2({
            theme: 'bootstrap'
        });

        jQuery('#selectValue + .select2').hide();

        Object.keys(this.mapFiltersValue).forEach(key => {
            if(this.mapFiltersValuesValue[key] != null && Object.keys(this.mapFiltersValuesValue[key]).length > 0)
                jQuery('#selectColumn').append(`<option value="${key}">${this.mapFiltersValue[key]}</option>`)
        });
        Object.keys(this.mapFiltersValuesValue).forEach(key => this.mapFilters.set(key, this.mapFiltersValuesValue[key]));
        jQuery('#selectColumn').on('change', () => this.setValueList());
        jQuery('#selectValue').on('change', (event) => this.saveFilter(event));
    }
}