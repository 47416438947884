import { Controller } from 'stimulus';

export default class extends Controller {
    static values = {
        urlSave: String
    }

    selectedInvoiceId = null;

    updateOrderLinesProductQuantity(row) {
        const invoiceId = row.attr('data-id');
        const invoiceLinesData = row.children('input[type="hidden"]');
        this.selectedInvoiceId = invoiceId;

        invoiceLinesData.each((_, line) => {
            let quantityInput = jQuery(`.order-line[data-product="${jQuery(line).attr('data-product')}"]`).find('input[type="number"]');
            let quantityInvoiceLine = parseInt(jQuery(line).val());
            quantityInput.val(quantityInvoiceLine);
        });
    }

    saveInvoicesLines(callback) {
        let data = this.collectInvoiceLinesData();
        console.log(this.urlSaveValue);
        jQuery.post(this.urlSaveValue, {
            invoicesLines: data
        }).then(callback).catch(null);
    }

    updateInvoiceLineQuantity(e) {
        if(!this.selectedInvoiceId) {
            e.preventDefault();
            e.stopPropagation();
            jQuery(e.target).val(0);
            return false;
        }

        let productId = jQuery(e.target).parentsUntil('tbody').last().attr('data-product');
        let inputInvoiceLine = jQuery(`.supplier-invoice[data-id="${this.selectedInvoiceId}"] input[data-product="${productId}"]`);
        if(inputInvoiceLine.length == 0) {
            inputInvoiceLine = jQuery(`<input type="hidden" data-product="${productId}" autocomplete="off" value="0"/>`);
            jQuery(`.supplier-invoice[data-id="${this.selectedInvoiceId}"]`).append(inputInvoiceLine);
        }
        
        let newQuantity = jQuery(e.target).val();
        let oldQuantity = Number.parseInt(inputInvoiceLine.val());
        let quantityLeftToBeInvoiced = Number.parseInt(jQuery(e.target).attr('data-quantity-left-to-be-invoiced')) - (newQuantity - oldQuantity);
        if(quantityLeftToBeInvoiced < 0 || newQuantity < 0) {
            e.preventDefault();
            e.stopPropagation();
            jQuery(e.target).val(oldQuantity)
            return false;
        }
        
        inputInvoiceLine.val(newQuantity);
        jQuery(e.target).attr('data-quantity-left-to-be-invoiced', quantityLeftToBeInvoiced);
        jQuery(e.target).parentsUntil('tbody').last().children('.field-integer').last().text(quantityLeftToBeInvoiced);
    }

    collectInvoiceLinesData() {
        var invoicesData = {};
        jQuery('.supplier-invoice[data-id]').each((_, invoiceRow) => {
            let invoiceId = jQuery(invoiceRow).attr('data-id');
            invoicesData[invoiceId] = {};
            jQuery(invoiceRow).find('input[type="hidden"]').each((_, input) => {
                let productId = jQuery(input).attr('data-product');
                invoicesData[invoiceId][productId] = jQuery(input).val();
            });
        });

        return invoicesData;
    }

    connect() {
        jQuery('.supplier-invoice').on('click', (e) => {
            const row = jQuery(e.target).parentsUntil('tbody').last();
            jQuery('.order-line[data-product] input[type="number"]').val(0);
            if(!row.hasClass('selected')) {
                jQuery('.supplier-invoice[data-id]').removeClass('selected');
                row.addClass('selected')
                this.updateOrderLinesProductQuantity(row);
            } else {
                this.selectedInvoiceId = null;
                row.removeClass('selected');
            }
        });
        jQuery('input[type="number"]').on('change', (e) => { this.updateInvoiceLineQuantity(e) });

        jQuery('#saveAndReturn').on('click', () => this.saveInvoicesLines(urlCallback => { document.location.href = urlCallback; }))
        jQuery('#saveAndContinue').on('click', () => this.saveInvoicesLines(_ => { document.location.reload(); }))
    }
}
