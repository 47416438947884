import { Controller } from 'stimulus';

export default class extends Controller {
    static values = {
        inputName: String,
    }

    connect() {
        let formWidget = jQuery('[name="' + this.inputNameValue + '"]').parentsUntil('.form-group').last();
        formWidget.find('trix-toolbar .trix-button-row').append(`
            <div class="insert-image-trix-editor">
                <input id="images" type="file" multiple accept="image/*"/><label for="images" title="Les images copiées-collées dans l'éditeur ne seront pas sauvegardées"><i class="fas fa-image"></i> Insérer une image</label>
            </div>
        `);
        let editorEl = formWidget.find('trix-editor').prop('editorController');

        formWidget.find('input[type=file]').on('change', ({ target })=> {
            let reader = new FileReader();
            reader.addEventListener('load', ()=> {
            let image = document.createElement('img');
            image.src = reader.result;
            let tmp = document.createElement('div');
            tmp.appendChild(image);
            editorEl.editor.insertHTML(tmp.innerHTML);
            target.value = '';
            }, false);
            reader.readAsDataURL(target.files[0]);
        });
    }
}