import { Controller } from 'stimulus';
import { Chart } from 'chart.js';

export default class extends Controller {
    chart;

    connect() {
        this.element.addEventListener('chartjs:pre-connect', this._onPreConnect);
        this.element.addEventListener('chartjs:connect', this._onConnect);
    }

    disconnect() {
        // You should always remove listeners when the controller is disconnected to avoid side effects
        this.element.removeEventListener('chartjs:pre-connect', this._onPreConnect);
        this.element.removeEventListener('chartjs:connect', this._onConnect);
    }

    _onPreConnect(event) {
        // The chart is not yet created
    }

    _onConnect(event) {
        // The chart was just created
        this.chart = event.detail.chart;
        this.chart.options.plugins.legend.labels.color = 'val(--gray-900)';
        this.chart.options.plugins.title.color = 'val(--gray-900)';
        var gradientStroke = this.chart.ctx.createLinearGradient(0, 0, 200, 700);
        gradientStroke.addColorStop(0, "rgba(255, 0, 0 , 0.6)");
        gradientStroke.addColorStop(0.25, "rgba(255, 125, 0 , 0.6)");
        gradientStroke.addColorStop(0.5, "rgba(125, 255, 0, 0.6)");
        gradientStroke.addColorStop(0.75, "rgba(0, 125, 255, 0.6)");
        gradientStroke.addColorStop(1, "rgba(0, 0, 255 , 0.6)");
        var gradientFill = this.chart.ctx.createLinearGradient(0, 0, 700, 200);
        gradientFill.addColorStop(0, "rgba(255, 0, 0 , 0.2)");
        gradientFill.addColorStop(0.25, "rgba(255, 125, 0 , 0.2)");
        gradientFill.addColorStop(0.5, "rgba(125, 255, 0, 0.2)");
        gradientFill.addColorStop(0.75, "rgba(0, 255, 125, 0.2)");
        gradientFill.addColorStop(1, "rgba(0, 0, 255 , 0.2)");

        // For instance you can format Y axis
        // this.chart.data.datasets[0].backgroundColor = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => `rgba(${(i + 1) * i % 255}, ${(i + 10) * i * i % 255}, ${(i + 100) * i * i * i % 255} , 1)`);
        // this.chart.data.datasets[0].borderColor = [1,2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => `rgba(${(i + 1) * i % 255}, ${(i + 10)  * i * i % 255}, ${(i + 100) * i * i * i % 255} , 0.7)`);
        // this.chart.update();

        /*// For instance you can listen to additional events
        event.detail.chart.options.onHover = (mouseEvent) => {

        };
        event.detail.chart.options.onClick = (mouseEvent) => {

        };*/
    }
}