export default class Select2Cascade {
    parent;
    child;
    urlChildOptions;
    initialChildOptions = [];
    strictLink = true;
    afterActions;

    _refreshChildOptions(items) {
        var newOptions = '';
        for(var id in items) {
            newOptions += '<option value="'+ id +'">'+ items[id] +'</option>';
        }

        let oldValue = this.child.val();
        let oldData = this.child.data();
        console.log(oldData);

        this.child.select2('destroy').html(newOptions).prop("disabled", false).data(oldData).select2({
            theme: 'bootstrap'
        });

        if(items[oldValue]) this.child.val(oldValue)
        else if(Object.keys(items).length == 1) this.child.val(Object.keys(items)[0]);
        else this.child.val('');
    }

    _refreshChild(items) {
        this.child.prop('disabled', false);

        switch(this.child.prop('nodeName').toLowerCase()) {
            case 'input':
                this.child.val(items);
                break;
            case 'select':
                this._refreshChildOptions(items);
                break;
        }

        this.child.trigger('change');

        this.afterActions.forEach(function (callback) {
            callback(this.parent, this.child, items);
        });
    }

    _handleChangeParent() {
        this.child.prop('disabled', true);

        if(!this.parent.val()) this._refreshChild(this.initialChildOptions);
        else jQuery.getJSON(this.urlChildOptions.replace(':parentId:', this.parent.val()), (items) => this._refreshChild(items));
    }

    linkSelects(parent, child, urlChildOptions) {
        this.parent = parent;
        this.child = child;
        this.urlChildOptions = urlChildOptions;
        this.afterActions = [];
        let that = this;

        // Register functions to be called after cascading data loading done
        this.then = function(callback) {
            that.afterActions.push(callback);
            return this;
        };

        this.parent.on('change', () => this._handleChangeParent());

        setTimeout(() => {
            if(this.child.prop('nodeName').toLowerCase() == 'select' && !this.strictLinkValue) {
                this.child.find('option').each((i, option) => {
                    this.initialChildOptions[jQuery(option).val()] = jQuery(option).text();
                });
            }
            if(this.parent.val()) this._handleChangeParent();
        }, 300);
    }
}
