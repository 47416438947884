
function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
}

function hashCode(str) {
    return str.split('').reduce((prevHash, currVal) => (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);
}

export default class TrixAttachmentHandler {
    editorWrapperSelector;
    attachments = {};

    constructor(editorWrapperSelector) {
        this.editorWrapperSelector = editorWrapperSelector;
    }

    static getAttachmentTag(fileHash, file) {
        return `<div id="${fileHash}" class="badge badge-secondary">
            <i class="fas fa-paperclip"></i>
            <span name="fileName" value="${file.name}">${file.name}</span>
            <span name="fileSize"> (${humanFileSize(file.size)})</span>
            <span class="fa fa-close"></span>
        </div>`;
    }

    getAttachments() {
        return this.attachments;
    }

    addEventListener() {
        let trixEditor = jQuery(this.editorWrapperSelector + ' trix-editor');
        if(trixEditor.length) {
            jQuery(this.editorWrapperSelector + ' trix-editor').on("trix-attachment-add", (e) => this.trixAddAttachmentEvent(e.target));
            jQuery(this.editorWrapperSelector + ' trix-editor').trigger('trix-attachment-add');
        }
        jQuery('.initial-attachments div .fa-close').on('click', (e) => jQuery(e.target).parentsUntil('.initial-attachments').last().remove());
    }

    trixAddAttachmentEvent = (e) => {
        let attachments = e.editor.composition.attachments;
        attachments.forEach(attachment => {
            if (attachment.file == null) {
                return;
            }
            let fileHash = hashCode(attachment.file.name);
            if(!this.attachments[fileHash]) this.attachments[fileHash] = {};
            this.attachments[fileHash] = attachment.file;
        });
        jQuery(e).parent().find('.editor-attachments div').remove();

        Object.keys(this.attachments).forEach(fileHash => {
            let file = this.attachments[fileHash];
            jQuery(e).parent().find('.editor-attachments').append(TrixAttachmentHandler.getAttachmentTag(fileHash, file));
        });

        e.editor.composition.attachments = [];
        jQuery('.editor-attachments div .fa-close').on('click', (e2) => this.removeAttachment(e2));
        setTimeout(() => jQuery(e).find('.attachment--file').remove(), 200);
    }

    removeAttachment = (e) => {
        let file = jQuery(e.target).parent();
        if (this.attachments) {
            delete this.attachments[file.attr('id')];
        }
        file.remove();
    }
}