import TrixAttachmentHandler from './trix-attachment-handler';

export default class MailPreview {
    static PREVIEW_PAGE_CLASS = 'mail-preview-page';

    _displayPreviewSendTo(preview) {
        if(Object.keys(preview.sendTo).filter(key => preview.sendTo[key] != '').length > 0)
            return Object.keys(preview.sendTo).map(key => `
                <span class="${preview.sendTo[key] ? '' : 'contact-unset' }">${(preview.sendTo[key] ? preview.sendTo[key] : 'N/A') + ' (' + key +')'}</span>
            `).join(', ');
        else return `<span class="contact-unset">Aucune adresse e-mail n'a été trouvée pour ce(s) contact(s) (${Object.keys(preview.sendTo).join(', ')}).
        <br/>Cet e-mail ne sera pas envoyé, à moins qu'il ne fasse directement suite à l'affectation de "Personnes liées" de type(s) : ${Object.keys(preview.sendTo).join(', ')}</span>`;
    }

    getMailPreviews(previews) {
        // Create nodes
        return Object.keys(previews).reverse().map(key => `
            <div class="${MailPreview.PREVIEW_PAGE_CLASS} ignorable-preview-page">
                <p class="page-title">Le mail ${previews[key].templateName} sera envoyé à :</p>
                <p class="page-subtitle">${this._displayPreviewSendTo(previews[key])}</p>
                <p class="page-legend">La signature du coordinateur de service sera ajouté au mail lors de l'envoi.<br/>Vous pouvez effectuer toute correction ou modification nécessaire ci-dessous.<br/>Pour ajouter des images, veuillez utiliser uniquement le bouton dédié.</p>
                ${previews[key].attachments
                    ? `<div class="initial-attachments" data-key="${key}"><span>Pièces-jointes par défault : </span>${previews[key].attachments.map((attachment) =>
                        `${TrixAttachmentHandler.getAttachmentTag(attachment.id, attachment)}`
                    ).join('')}</div>` : ''}
                <div>
                    <div data-controller="insert-image-trix-editor" data-insert-image-trix-editor-input-name-value="${key}" style="display:none"></div>
                    <input id="${key}" type="hidden" name="${key}" autocomplete="off">
                    <div class="editor-attachments"><span>Autres pièces-jointes (max 12 Mo par fichier) :</span></div>
                    <trix-editor data-id="${key}" input="${key}" rows="10"></trix-editor>
                </div>
                <script>jQuery('.${MailPreview.PREVIEW_PAGE_CLASS} trix-editor[input="${key}"]').get(0).editor.insertHTML(\`${previews[key].preview}\`)</script>
            </div>
        `);
    }

    static async getSubstepProgressCompletionMailPreviews(substepProgressId) {
        return (new MailPreview()).getMailPreviews(await jQuery.get('/api/substep/' +  substepProgressId + '/validation/mail/preview'));
    }

    static async getSubstepProgressUncompletionMailPreviews(substepProgressId) {
        return (new MailPreview()).getMailPreviews(await jQuery.get('/api/substep/' +  substepProgressId + '/invalidation/mail/preview'));
    }

    static async getSubstepProgressAlertSalesmanMailPreviews(substepProgressId) {
        return (new MailPreview()).getMailPreviews(await jQuery.get('/api/substep/' +  substepProgressId + '/alert-salesman/mail/preview'));
    }

    static async getServiceProjetManagerAssignationPreview(projectManagerId, serviceData) {
        return (new MailPreview()).getMailPreviews(await jQuery.ajax({
            method: 'POST',
            url: '/api/service/alert-project-manager-assignation/preview',
            data: serviceData
        }));
    }

    static async getSubstepMailCompletionPreviews(substepId, requestData = {}) {
        Object.keys(requestData).forEach(key => {
            let matches = key.match(/([a-z]*)\[([a-z\_]*)\]/i);
            if(matches && matches.length == 3) {
                if(!requestData[matches[1]]) requestData[matches[1]] = {};
                requestData[matches[1]][matches[2]] = requestData[key];
                delete requestData[key];
            }
        });
        return (new MailPreview()).getMailPreviews(await jQuery.post('/api/service/substep/' +  substepId + '/mail/preview', { formValues: requestData }));
    }
}