import { Controller } from 'stimulus';

export default class extends Controller {
    actionEventPrevented;

    connect() {
        jQuery('[data-action-name="syncClientsWithCRM"]').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.actionEventPrevented = e;
            jQuery('[data-action-name="syncClientsWithCRM"]').addClass('active');
            jQuery('[data-action-name="syncClientsWithCRM"]').attr('disabled', true);
            jQuery('[data-action-name="syncClientsWithCRM"] i').addClass('fa-spin');

            jQuery.ajax({
                url: '/api/clients/sync/',
                complete: () => document.location.href = jQuery('[data-action-name="syncClientsWithCRM"]').attr('href'),
                timeout: 0
            });
        });

        jQuery('[name="query"]').on('change', (e) => window.location.href = jQuery(e.target).data('url-refresh') + '&query=' + jQuery(e.target).val())
        jQuery('[name="query_contact"]').on('change', (e) => window.location.href = jQuery(e.target).data('url-refresh') + '&query_contact=' + jQuery(e.target).val())
    }
}
