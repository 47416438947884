import { uid } from 'uid';

const modalIdPrefix = 'modalPages'
const previousButtonId = 'previousModalSubStep';
const ignoreButtonId = 'ignoreModalSubStep';
const nextButtonId = 'nextModalSubStep';
const confirmButtonId = 'confirmModalSubStep';

export default class PagesModal {
    pages;
    confirmCallback;

    modalId;
    modal;
    previousBtn;
    ignoreBtn;
    nextBtn;
    confirmBtn;
    pagesWrapper;

    constructor(title) {
        const id = uid();
        const modalId = modalIdPrefix + '_' + id;
        const modalSelector = '#' + modalId;

        jQuery('body').prepend(`
            <div class="modal pages-modal fade" id="${modalId}" tabindex="-1" href="#" role="dialog" aria-labelledby="${modalId}_Label" aria-hidden="true">
				<div class="modal-dialog" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="${modalId}_Label">${title}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							    <span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body form-group">
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
							<button type="button" class="btn btn-light" id="${previousButtonId}">Précédent</button>
							<button type="button" class="btn btn-danger" id="${ignoreButtonId}" title="Passer à l'étape suivante en ignorant l'envoi de l'étape actuelle">Ignorer</button>
							<button type="button" class="btn btn-primary" id="${nextButtonId}">Suivant</button>
							<button type="button" class="btn btn-primary" id="${confirmButtonId}">Confirmer</button>
						</div>
					</div>
				</div>
			</div>
        `);
        this.modal = jQuery(modalSelector);
        this.pagesWrapper = jQuery(modalSelector + ' .modal-body');
        this.previousBtn = jQuery(modalSelector + ' #' + previousButtonId);
        this.ignoreBtn = jQuery(modalSelector + ' #' + ignoreButtonId);
        this.nextBtn = jQuery(modalSelector + ' #' + nextButtonId);
        this.confirmBtn = jQuery(modalSelector + ' #' + confirmButtonId);

        this.confirmBtn.on('click', () => this._runConfirmCallback());

        this.previousBtn.on('click', () => {
            this.changeModalPage('prev');
            let currentPage = this.pagesWrapper.children('div:not(.hide)');
            currentPage.find('input').attr('disabled', null);
        });
        this.ignoreBtn.on('click', () => {
            let currentPage = this.pagesWrapper.children('div:not(.hide)');
            currentPage.find('input').attr('disabled', 'disabled');
            if(currentPage.is(":last-child")) this._runConfirmCallback();
            else this.changeModalPage('next');
        });
        this.nextBtn.on('click', () => this.changeModalPage('next'));
        this._updateButtons();
    }

    _updateButtons() {
        const firstPreview = this.pagesWrapper.children().first();
        const lastPage = this.pagesWrapper.children().last();
        if(!jQuery(this.previewPageSelector)) {
            this.previousBtn.hide();
            this.nextBtn.hide();
            this.ignoreBtn.hide();
            this.confirmBtn.show();
        } else {
            const currentPage = this.pagesWrapper.children('div:not(.hide)');
            if(currentPage.hasClass('ignorable-preview-page')) this.ignoreBtn.show();
            else this.ignoreBtn.hide();

            if(!firstPreview.hasClass('hide')) this.previousBtn.hide();
            else this.previousBtn.show();
    
            if(!lastPage.hasClass('hide')) this.nextBtn.hide() && this.confirmBtn.show();
            else this.nextBtn.show() && this.confirmBtn.hide();
        }
    }

    _runConfirmCallback() {
        if(this.confirmCallback) {
            this.confirmBtn.attr('disabled', true);
            this.ignoreBtn.attr('disabled', true);
            this.confirmCallback();
        }
    }

    changeModalPage(direction) {
        let currentPage = this.pagesWrapper.children('div:not(.hide)');
        currentPage[direction]().removeClass('hide');
        currentPage.addClass('hide');
        this._updateButtons();
    }

    addPage(page, i = -1) {
        if(i < 0) this.pagesWrapper.append(page);
        else this.pagesWrapper.children(':nth-child(' + i + ')').before(page);
        return this;
    }

    removePage(i) {
        this.pagesWrapper.children(':nth-child(' + i + ')').remove();
        return this;
    }

    clearPages(selector) {
        if(!selector) this.pagesWrapper.empty();
        else this.pagesWrapper.children(selector).remove()
        return this;
    }

    addPages(...pages) {
        this.pagesWrapper.append(pages);
        this._updateButtons();
        return this;
    }

    onConfirm(callback) {
        this.confirmCallback = callback
        return this;
    }

    getHTMLId() {
        return this.modal.attr('id');
    }

    show() {        
        if(!this.pagesWrapper.children().length)
            this._runConfirmCallback();
        else {
            this.pagesWrapper.children().addClass('hide');
            this.pagesWrapper.children().first().removeClass('hide');
            this.confirmBtn.attr('disabled', false);
            this.ignoreBtn.attr('disabled', false);
            this._updateButtons();

            jQuery.noConflict(false);
            this.modal.modal('show');
        }
    }
}