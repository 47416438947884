import { Controller } from 'stimulus';
import Select2Cascade from '../js/select2-cascade';

export default class extends Controller {
    connect() {
        let formName = jQuery('.ea-new-form, .ea-edit-form').attr('name');

        new Select2Cascade().linkSelects(jQuery(`[name="${formName}[serviceType]"]`), jQuery(`[name="${formName}[partner]"]`), '/api/service/service-type/:parentId:/partners');
        new Select2Cascade().linkSelects(jQuery(`[name="${formName}[partner]"]`), jQuery(`[name="${formName}[technology]"]`), '/api/service/partner/:parentId:/technologies');
        new Select2Cascade().linkSelects(jQuery(`[name="${formName}[technology]"]`), jQuery(`[name="${formName}[distributor]"]`), '/api/service/technology/:parentId:/distributors');
    }
}