import Quagga from 'quagga';
import { Controller } from 'stimulus';
import $ from 'jquery';

const NUM_STEPS = 6;

export default class extends Controller {
    static values = {
        urlCallback: String,
        stockType: String
    }

    barCodes = [];
    sent = false;
    loading = false;
    config = {
        inputStream : {
            name : "Live",
            type : "LiveStream",
            size : 640,
            target: this.element
        },
        locator: {
            patchSize: "large",
            halfSample: true
        },
        numOfWorkers: 0,
        frequency: 10,
        decoder: {
            readers : [{
                format: "ean_reader",
                config: {
                    // supplements: [
                    //     'ean_8_reader'
                    // ]
                }
            }]
        },
        debug: {
            drawBoundingBox: true,
            showFrequency: true,
            drawScanline: true,
        },
        locate: true,
    }

    callbackScan(err) {
        if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
            // safely access `navigator.mediaDevices.getUserMedia`
            console.log("Initialization finished. Ready to start");
            Quagga.start();
            jQuery('#pre-scan-form').hide();
        } else {
            alert('Votre navigateur n\'est pas capable d’accéder à la caméra de votre appareil, vous allez être redirigé');
            window.location.href = new URL(window.location.href).searchParams.get('referrer');
        }
    }

    _getMedian(arr) {
        arr.sort((a, b) => a - b);
        const half = Math.floor( arr.length / 2 );
        if (arr.length % 2 === 1) // Odd length
          return arr[ half ];
        return (arr[half - 1] + arr[half]) / 2.0;
    }

    isValid(codeResult) {
        const errors = codeResult.decodedCodes
           .filter(_ => _.error !== undefined)
           .map(_ => _.error);

        //Good result for code_128 : median <= 0.08 and maxError < 0.1
        const median = this._getMedian(errors);
        return median < 0.08
    }

    callbackDetect(result) {
        if(result == null || result.codeResult == null || !this.isValid(result.codeResult)) return;
        let scanCode = result.codeResult.code;

        if (this.sent === false) {
            this.sent = true;
            setTimeout(() => {
                var drawingCtx = Quagga.canvas.ctx.overlay,
                drawingCanvas = Quagga.canvas.dom.overlay;
                drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
            }, 1000);

            jQuery('.message').removeClass('error').addClass('active success');
            jQuery('.message').html('<p style="color: #fff;">{% trans %}Lecture réussie{% endtrans %}</p>').show();
            jQuery('.ea-scanBarCode-form').css('display', 'block');
            
            this.barCodes = jQuery('#choosed-codes input').toArray().map(e => jQuery(e).val()).filter(v => v != null && v != '');
            this.barCodes.push(scanCode)
            this.reloadEntries();

            setTimeout(() => {
                this.sent = false;
                jQuery('.message').removeClass('active');
            }, 2000);
        }
    }

    callbackProcess(result) {
        var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

        if (result && !this.sent) {
            if (result.boxes) {
                drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                result.boxes.filter(function (box) {
                    return box !== result.box;
                }).forEach(function (box) {
                    console.log('processed');
                    Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "green", lineWidth: 2});
                });
            }

            if (result.box) {
                Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 2});
            }

            if (result.codeResult && result.codeResult.code) {
                Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
            }
        }
    }

    checkDuplicates() {
        var barcodes = [];
        jQuery('#choosed-codes').children().each((i, divSerialNumber) => {
            let serialNumber = jQuery(divSerialNumber).children().first().val();
            if(barcodes.includes(serialNumber)) {
                jQuery(divSerialNumber).addClass('duplicate');
                jQuery(divSerialNumber).children().first().attr('title', "Erreur doublon : veuillez vérifier votre saisie" );
            }
            else {
                jQuery(divSerialNumber).removeClass('duplicate');
                jQuery(divSerialNumber).children().first().attr('title', null);
                barcodes.push(serialNumber);
            }
        });
    }

    reloadEntries() {
        jQuery.post('/api/stock/scan-barcodes/view', {
            'productId': jQuery("#StockInput_product, #StockOutput_product").find(':selected').val(),
            'purchaseOrderId': jQuery("#StockInput_purchaseOrder").find(':selected').val(),
            'barCodes': this.barCodes,
            'typeStock': jQuery("#new-StockInput-form").length > 0 ? 'input' : 'output'
         }, (data) => {
            this.loading = false;
            jQuery('.scan-codes').html(data);
            setTimeout(() => {
                jQuery('#choosed-codes input').last().trigger('focus');
                jQuery('.page-actions').css('display', this.barCodes.length ? 'flex' : 'none');

                if(jQuery('.invalid-feedback').length > 0) jQuery('.page-actions > button').attr('disabled', true);
                else jQuery('.page-actions > button').attr('disabled', false);
                
                jQuery('#choosed-codes input').attr("form", jQuery('#pre-scan-form').children().first().attr('id'))
                jQuery('#choosed-codes input').on('keydown', e => {
                    if(e.key == 'Enter') {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        if(!this.loading) {
                            this.loading = true;
                            jQuery(e.target).trigger('change');
                        }
                    }
                });

                jQuery('#choosed-codes input').on('change', e => {
                    this.barCodes = jQuery('#choosed-codes input').toArray().map(e => jQuery(e).val()).filter(v => v != null && v != '');
                    this.reloadEntries();
                })

                jQuery('#choosed-codes').children().find('.btn').on('click', (e) => {
                    const removeIndex = this.barCodes.indexOf(jQuery(e.target).closest('div').find('input').val());
                    if(removeIndex < 0) this.barCodes.shift();
                    else this.barCodes.splice(removeIndex, 1)
                    this.reloadEntries();
                });
                jQuery('#available-codes').find('.btn').on('click', (e) => {
                    this.barCodes = jQuery('#choosed-codes input').toArray().map(e => jQuery(e).val()).filter(v => v != null && v != '');
                    this.barCodes.push($(e.target).data('code'));
                    this.reloadEntries();
                });
            }, 200);
         }, 'html'
      ).always(e => {
         this.isLoading = false;
      })
    }

    connect() {
        Quagga.onDetected((result) => this.callbackDetect(result));
        Quagga.onProcessed((result) => this.callbackProcess(result));
        jQuery('#btn-continue-form-barcode').on('click', () => {
            this.reloadEntries();
            if(
                jQuery('#StockInput_product').val() && jQuery('#StockInput_storageSite').val()
                || jQuery('#StockOutput_product').val() && jQuery('#StockOutput_pointingEntity_service').val()
                || jQuery('#StockOutput_product').val() && jQuery('#StockOutput_pointingEntity_supportTicket').val())
                Quagga.init(this.config, (err) => this.callbackScan(err));
        });
    }
}
