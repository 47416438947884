import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        let qualificationForm = jQuery(jQuery('form[name="SupportTicket"]').children('.field-form_panel').get(2));

        if(jQuery('#SupportTicket_queue').val() != 0) qualificationForm.hide();

        jQuery('#SupportTicket_queue').on('change', e => {
            if(jQuery(e.target).val() === '0') qualificationForm.show(1000);
            else qualificationForm.hide(1000);
        })
    }
}
