import { Controller } from 'stimulus';

export default class extends Controller {
    static values = {
        inputName: String,
        defaultClient: Object
    }

    connect() {
        jQuery('[name="' + this.inputNameValue + '"]').select2({
            theme: 'bootstrap',
            placeholder: 'Rechercher un client',
            allowClear: true,
            data: !jQuery.isEmptyObject(this.defaultClientValue) ? [this.defaultClientValue] : [],
            ajax: {
                transport: (params, success, failure) => {
                    return !params.data.term && !jQuery.isEmptyObject(this.defaultClientValue) ? success([this.defaultClientValue])
                    : jQuery.get('/api/clients/' + params.data.term + '/' + (params.data.page || 0))
                        .done(success)
                        .fail(failure);
                },
                delay: 250,
                processResults: (data) => {
                    return {
                      results: data,
                      pagination: {
                        more: data && data.length == 50
                      }
                    };
                }
            },
        })
        jQuery('[name="' + this.inputNameValue + '"]').val(!jQuery.isEmptyObject(this.defaultClientValue) ? this.defaultClientValue.id : null).trigger('change');
    }
}